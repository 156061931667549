import React, { Component } from 'react'
import Back8 from '../../images/background/banner-bg-1.jpg'
import Button from 'react-bootstrap/Button';
import {Link} from 'react-router-dom'
const linkedIn = 'https://www.linkedin.com/pulse/deployment-net-azure-kubernetes-services-kloudping%3FtrackingId=4ZKjMLWVXq8C%252F8U2GAca4Q%253D%253D/?trackingId=4ZKjMLWVXq8C%2F8U2GAca4Q%3D%3D';

function scrollToContact() {
    const contactSection = document.getElementById('contact');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  }

class MAzure extends Component {
  componentDidMount() {
     }
  render() {
      return (
          <>
              <section className="inner-banner alternate">
        <div className="image-layer" style={{ background: `url(${Back8})`}}></div>
        <div className="auto-container">
            <div className="inner">
                <div className="title-box">
                    <h1>Work with a Team of Azure Experts   </h1>
                    <div className="d-text">Building a relationship between IT Services</div>
                </div>
            </div>
        </div>
              </section>
              <div className="sidebar-page-container services-page">
        <div className="auto-container">
                      <div className="row clearfix">
                      <div className="content-side col-lg-8 col-md-12 col-sm-12">
                    <div className="services-content">
                        <div className="service-details">
                            <div className="image-box">
                                          <img src={require('../../images/resource/aws-image.png')} alt="aws-image"/>
                            </div>
                            
                            <div className="content">
                                <h3>Azue CaseStudy</h3>
                                <div className="text">
                                    <h4>How to create an Azure resource using Terraform, a popular infrastructure as code tool.</h4>
                                <p>The resource I will create is an Azure container registry, which is a service that allows you to store and manage container images and artifacts in a private registry. I will also show you how to deploy a static web app and an Azure Kubernetes service that use the container registry.                             </p>
                                    <p>The first step is to install Terraform on your machine and configure it to work with Azure. You can follow the <a href="https://docs.microsoft.com/en-us/azure/developer/terraform/install-configure">official documentation </a> here.</p>
                                    <p>The next step is to create a Terraform configuration file that defines the Azure resource you want to create. The file should have a .tf extension and use the Terraform syntax. Here is an example of a configuration file that creates an Azure container registry:</p>
                                    <div className="more-link"><a href={linkedIn} className="theme-btn btn-style-one"><span className="btn-title" >Learn More</span></a></div>
                                </div>
                                <h3>Azure Enterprise Solutions</h3>
                                <div className="text">
                                    <p>Kloudping provides comprehensive Java enterprise solutions, enabling businesses to build scalable and secure applications that meet the complex demands of enterprise-level environments. Our Java experts leverage cutting-edge technologies and frameworks to deliver robust and reliable enterprise solutions.</p>

                                    <ul className="list-style-one">
                                        <li>Scalable and secure applications for enterprise</li>
                                        <li>Cutting-edge Java technologies and frameworks</li>
                                        <li>Robust and reliable solutions</li>
                                        <li>Enterprise-level Java solutions</li>   
                                        <Button variant="primary" size="lg"   onClick={() => scrollToContact()}>   Let's Connect for your Project </Button>{' '}                  
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                    <aside className="sidebar services-sidebar">
                        
                        <div className="sidebar-widget services-widget">
                            <div className="widget-inner">
                                <ul>
                                    <li><a href='/Devops'>Data Infrastructure</a></li>
                                    <li><a href='/cloudintegration' >IT Cloud Integration</a></li>
                                    <li><a href='/Migration'>IT Startup Projects</a></li>
                                    <li><a href='/productengineering'>Product Engineering</a></li>
                                    <li className="active"><a href='/businesssecurity' >Business Security</a></li>
                                </ul>
                            </div>
                        </div>
                        
                        
                        <div className="sidebar-widget info-widget">
                            <div className="widget-inner">
                                          <div className="image"><Link to="contact"><img src={require('../../images/resource/featured-image-4.jpg')} alt="featured-image-4"/></Link></div>
                                <div className="lower">
                                    <div className="subtitle">Got any Questions? <br/>Call us Today!</div>
                                    <div className="icon-box"><span className="flaticon-telephone"></span></div>
                                    <div className="phone"><a href="tel:+91 89035 00146">+91 89035 00146</a></div>
                                    <div className="email"><a href="mailto:info@kloudping.com">info@kloudping.com</a></div>
                                </div>
                            </div>
                        </div>
                    </aside>
                </div>
                
            </div>
        </div>
    </div>

    <div className="theme-separator"></div>
              <section className="contact-section-two" id="contact">
                <div className="auto-container">
                    
                    <div className="upper-row">
                        <div className="row clearfix">

                            <div className="text-column col-lg-6 col-md-12 col-sm-12">
                                <div className="inner">
                                    <div className="sec-title">
                                        <div className="upper-text">Kloudping - Send us a Message</div>
                                        <h2>Do You Have Any Questions? We’ll Be Happy To Assist!</h2>
                                        <div className="lower-text">We will respond to your inquiry as soon as possible. Right now, we receive many inquiries and it may take a little longer than normal before you get a reply. If you need urgent help, we recommend that you call us during our opening hours..</div>
                                    </div>

                                    <div className="social-links">
                                        <ul className="clearfix">
                                            <li><a href="https://www.linkedin.com/company/kloudping" target='_blank'><span className="fab fa-linkedin"></span></a></li>
                                            <li><a href="#"><span className="fab fa-twitter"></span></a></li>
                                            <li><a href="https://www.youtube.com/@Kloudping"  target='_blank'><span className="fab fa-youtube"></span></a></li>
                                            <li><a href="https://wa.me/+918903500146" target='_blank'><span className="fab fa-whatsapp"></span></a></li>
                                            <li><a href="#"><span className="fab fa-facebook-square"></span></a>
                                            </li> 
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="form-column col-lg-6 col-md-12 col-sm-12">
                               <div className="inner">
                                 <div className='form-box'>
                                      <div className="default-form contact-form">
                                            <div className="row clearfix">
                                                   <h2>Contact Us</h2> 
                                                   <p>Fill out the form and we'll be in touch soon!</p>
                                                <div className="col-lg-6 col-md-6 col-sm-12 form-group txtOnly">
                                                    <input id="name" type="text" name="username" placeholder="Your Name" required="" defaultValue="" />
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                    <input id ="email"  type="email" name="email" placeholder="Email"  />
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 form-group numberOnly">
                                                    <input id="phone"   type="text" name="phone"  placeholder="Phone"  />
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                    <input  id="subject"  type="text" name="subject" placeholder="Subject"  />
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                                    <textarea id="message" name="message" placeholder="Message" ></textarea>
                                                </div>
                                                <div className="form-group col-md-12 col-sm-12">
                                                    <button  type="submit" className="theme-btn btn-style-one"><span className="btn-title" >Make a Request</span></button>
                                                </div>
                                           </div>    
                                      </div>
                                  </div>
                               </div>
                            </div>

                        </div>
                    </div>

                   
                </div>
            </section>
    
              <div className="theme-separator"></div>
              </>
        )
    }
}
export default MAzure
