const data = {
    kid: {
        development: {
            name: "Thanks For Choosing Kloudping",
            imageUrl: "https://www.kloudping.com/static/media/logo.29a079f8e3cdd2c2af98.png",
            link: "https://www.linkedin.com/company/kloudping/about/"
       
        },
        devops: {
            name: "Battery Bike",
           
        },
        sales: {
            name: "Battery Bike",
        } 
    },
    teenagers: {
        development: {
            name: "Leader Scout MTB 26T Mountain Bicycle",
        },
        devops: {
            name: "Red New Hero Electric Bike",
          
        },
        sales: {
            name: "Battery Bike",
           
        }
    },
    adults: {
        development: {
            name: "Hero Splendor+",
           
        },
        devops: {
            name: "KTM Duke 200",
            
        },
        sales: {
            name: "Battery Bike",
        }
    }
}

export default data;