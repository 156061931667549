import React, { Component } from 'react'
import $ from "jquery"
import {Link} from 'react-router-dom'

class privacypolicy extends React.Component {



    componentDidMount() {


        $(".txtOnly").bind("keypress", function (event) {
            if (event.charCode != 0) {
                var regex = new RegExp("^[a-zA-Z]+$");
                var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
                if (!regex.test(key)) {
                    event.preventDefault();
                    return false;
                }
            }
        });
        $(".numberOnly").bind("keypress", function (event) {
            if (event.charCode != 0) {
                var regex = new RegExp("^[0-9]+$");
                var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
                if (!regex.test(key)) {
                    event.preventDefault();
                    return false;
                }
            }
        });

        /* $('.numberOnly').keypress(function(event)
         {
             if(event.which != 8 && isNaN(String.fromCharCode(event.which))){
                 event.preventDefault(); //stop character from entering input
             }
     
         });
         $( ".txtOnly" ).keypress(function(e) {
             var key = e.keyCode;
             if (key >= 48 && key <= 57) {
                 e.preventDefault();
             }
         });*/


        $("button").click(function () {
            debugger;

            var model = {
                name: document.getElementById('name').value,
                phone: document.getElementById('phone').value,
                email: document.getElementById('email').value,
                subject: document.getElementById('subject').value,
                message: document.getElementById('message').value

            };
            $.ajax({
                url: "https://localhost:8000/api/SendEmail",
                type: "POST",
                data: JSON.stringify(model),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (data) {

                    debugger;
                    alert("Data: " + model + "\nStatus: ");
                }
            })



        });





    }
    render() {

        return (
            <>
                <section className="inner-banner alternate">

                    <div className="auto-container">
                        <div className="inner">
                            <div className="title-box">
                                <h1>Privacy Policy</h1>
                                <div className="d-text">Building a relationship between IT Services</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="about-section-three">
                    <div className="auto-container">
                        <div className="inner__container">
                            <div>
                                <h3>YOUR PRIVACY IS IMPORTANT TO US.</h3>
                                <p>
                                    <strong>Last Updated: 30-Sep-22</strong>
                                </p>
                                <p>
                                    This Privacy Policy describes how your personal information is collected, used, and shared when you visit or fill out a form on{' '}
                                    <a href="https://www.kloudping.com/">www.kloudping.com</a>&nbsp;.
                                </p>
                                <p>
                                    Kloudping IT Solutions Pvt,Ltd its subsidiaries and branch operations hereinafter is committed to respect your privacy and choices. The statement highlights our privacy practices regarding Personal Information that we collect and store about you through this website,{' '}
                                    <a href="https://www.kloudping.com">www.kloudping.com</a>&nbsp; and also for those Personal Information that you provide us while filling out forms or requesting case studies or similar kind of resources on our Site.
                                </p>
                                <h4>
                                    <strong>INFORMATION WE COLLECT</strong>
                                </h4>
                                <p>
                                    For the purposes of this privacy statement, 'Personal Information' is any data which relates to an individual who may be identified from that data or from a combination of a set of data, and other information which is in possession of Kloudping.
                                </p>
                                <p>Name</p>
                                <p>Job Title</p>
                                <p>Contact Information including Email address and Phone Number</p>
                                <p>Demographic information such as postcode, preferences and interest</p>
                                <p>Other information relevant to customer surveys and/or offers</p>
                                <p>
                                    When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. We refer to this automatically-collected information as “Device Information.”
                                </p>
                                <p>
                                    We collect Device Information using the following technologies:
                                </p>
                                <p>
                                    “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, visit{' '}

                                </p>
                                <p>
                                    “Log files” track actions occurring on the Site and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.
                                </p>
                                <p>
                                    “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.
                                </p>
                                <p>
                                    When we talk about “Information We Collect” in this Privacy Policy, we are talking both about Personal Information and Device Information.
                                </p>
                                <h4>
                                    <strong>HOW DO WE USE YOUR PERSONAL INFORMATION?</strong>
                                </h4>
                                <p>
                                    We use your Personal Information for the following purposes:
                                </p>
                                <p>to provide better usability, troubleshooting, and site maintenance;</p>
                                <p>to understand which parts of the website are visited and how frequently;</p>
                                <p>to identify you once you fill forms on our website;</p>
                                <p>to contact you and respond to your questions or requests; and</p>
                                <p>to provide access to desirable content based on your preferences.</p>
                                <p>to process job application and alumni related requests, more details about which are available on corresponding portals of this website.</p>
                                <h4>
                                    <strong>DISCLOSURE YOUR PERSONAL INFORMATION</strong>
                                </h4>
                                <p>
                                    We do not sell, distribute, or lease your personal information to third parties unless we have your permission. We share your Personal Information when it is necessary only to:
                                </p>
                                <p>To our subsidiaries, branch locations.</p>
                                <p>To fulfill the purpose for which you provide it.</p>
                                <p>For any other purpose disclosed by us when you provide the information.</p>
                                <p>With your consent.</p>
                                <p>
                                    We may also disclose your personal information:
                                </p>
                                <p>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</p>
                                <p>
                                    To enforce or apply our terms of sale and other agreements, including for billing and collection purposes.
                                </p>
                                <p>
                                    If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address. We will promptly correct any information found to be incorrect.
                                </p>
                                <p>
                                    You may choose to restrict the collection or use of your personal information in the following ways:
                                </p>
                                <p>You may choose not to input any personal information when prompted</p>
                                <p>
                                    If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by{' '}
                                    <a href="https://www.kloudping.com/contact">contact us</a>.
                                </p>
                                <h4>
                                    <strong>DATA SECURITY</strong>
                                </h4>
                                <p>
                                    We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website.
                                </p>
                                <h4>
                                    <strong>MINORS?? Is this required?</strong>
                                </h4>
                                <p>
                                    Our Website is not intended for children under 13 years of age. No one under age 13 may provide any information to the Website. We do not knowingly collect personal information from children under 13. If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 13, please contact us using the Contact Information below.
                                </p>
                                <h4>
                                    <strong>CHANGES</strong>
                                </h4>
                                <p>
                                    We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons. The date the privacy policy was last revised is identified at the top of the page. You are responsible for periodically visiting our Website and this privacy policy to check for any changes.
                                </p>
                                <h4>
                                    <strong>CONTACT US</strong>
                                </h4>
                                <p>
                                    For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us using the below information:
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
               
            </>
        )
    }

}
export default privacypolicy