import React, { Component } from 'react'
import Back8 from '../../images/background/banner-bg-1.jpg'
import CountUp from "react-countup";
import Button from 'react-bootstrap/Button';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useState } from 'react';
import WOW from "wowjs";

function scrollToContact() {
  const contactSection = document.getElementById('contact');
  if (contactSection) {
    contactSection.scrollIntoView({ behavior: 'smooth' });
  }
}
const scrollToSection = (event, sectionId) => {
  event.preventDefault();
  const element = document.getElementById(sectionId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

class Dotnet extends Component {


  state = {
    selectedSection: 'application',
  };

  scrollToSection = (sectionId) => {
    this.setState({ selectedSection: sectionId });
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  componentDidMount() {

  }

  constructor(props) {
    super(props);
    this.state = {
      content: 'webdevelopment', // Initial content
    };
  }

  changeContent = (newContent) => {
    this.setState({ content: newContent });
  };


  render() {
    const { selectedSection } = this.state;
    return (
      <>
        <section className="inner-banner alternate">
          <div className="image-layer" style={{ background: `url(${Back8})` }}></div>
          <div className="auto-container">
            <div className="inner">
              <div className="title-box">
                <h1>Hire Experienced .Net Developers</h1>
              </div>
            </div>
          </div>

        </section>
        <div className="sidebar-page-container services-page">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="content-side col-lg-8 col-md-12 col-sm-12">
                <div className="services-content">
                  <div className="service-details">
                    <div className="content">
                      <div id="webdevelopment" style={{ display: selectedSection === 'webdevelopment' ? 'block' : 'none' }}>
                        <h3>.Net Web Application Development</h3>
                        <div className="text">
                          <p>Kloudping specializes in ASP.NET web development, creating robust and scalable web applications using the ASP.NET framework. Our expert developers leverage the power of ASP.NET to deliver high-performing and secure web solutions.</p>

                          <ul className="list-style-one">
                            <li>Robust and scalable web applications</li>
                            <li>ASP.NET framework for enhanced functionality</li>
                            <li>High-performing and secure web solutions</li>
                            <li>Reliable ASP.NET web development services</li>
                            <Button variant="primary" size="lg" onClick={() => scrollToContact()}>   Let's Connect for your Project </Button>{' '}
                          </ul>
                        </div>
                      </div>
                      <div id="application" style={{ display: selectedSection === 'application' ? 'block' : 'none' }}>
                        <h3>.NET Application Modernization</h3>
                        <div className="text">
                          <p>Kloudping  offers comprehensive .NET application modernization services, helping businesses transform their legacy .NET applications into modern, cloud-ready solutions. Our experts utilize industry best practices and cutting-edge technologies to revitalize your applications for improved performance and scalability.</p>

                          <ul className="list-style-one">
                          <li>Transformation of legacy .NET applications</li>
                            <li>Industry best practices</li>
                            <li>Enhanced performance and scalability</li>
                            <li>Cloud-ready architecture for .NET applications</li>
                            <Button variant="primary" size="lg" onClick={() => scrollToContact()}>   Let's Connect for your Project </Button>{' '}
                          </ul>
                        </div>
                      </div>
                      <div id="ecommerce" style={{ display: selectedSection === 'ecommerce' ? 'block' : 'none' }}>
                        <h3>.NET E-commerce Development</h3>
                        <div className="text">
                          <p>Kloudping specializes in .NET e-commerce development, creating powerful and secure online stores using the .NET framework. Our expert developers leverage the capabilities of .NET to deliver scalable, user-friendly, and feature-rich e-commerce solutions.</p>

                          <ul className="list-style-one">
                            <li>Secure e-commerce websites with .NET</li>
                            <li>Seamless shopping experiences</li>
                            <li>Scalable architecture to accommodate business need</li>
                            <li>Expertise in leveraging .NET for e-commerce development</li>
                            <Button variant="primary" size="lg" onClick={() => scrollToContact()}>   Let's Connect for your Project </Button>{' '}
                          </ul>
                        </div>
                      </div>
                      <div id="apidevelopment" style={{ display: selectedSection === 'apidevelopment' ? 'block' : 'none' }}>
                        <h3>.NET API Development</h3>
                        <div className="text">
                          <p>Kloudping offers comprehensive .NET API development services, creating robust and scalable APIs using the .NET framework. Our expert developers leverage the power of .NET to build secure and high-performing APIs that facilitate seamless integration and communication between applications.
                          </p>

                          <ul className="list-style-one">
                            <li>Robust API development with .NET</li>
                            <li>High-performing APIs for seamless integration</li>
                            <li>Facilitating communication between applications</li>
                            <li>Expertise in .NET for API development</li>
                            <Button variant="primary" size="lg" onClick={() => scrollToContact()}>   Let's Connect for your Project </Button>{' '}
                          </ul>
                        </div>
                      </div>
                      <div id="microservice" style={{ display: selectedSection === 'microservice' ? 'block' : 'none' }}>
                        <h3>.NET Microservices Development</h3>
                        <div className="text">
                          <p>Kloudping specializes in .NET microservices development, leveraging the power of the .NET framework to build scalable and resilient microservices-based applications. Our expert developers design and implement microservices using industry best practices for improved modularity and flexibility.</p>
                          <ul className="list-style-one">
                            <Button variant="primary" size="lg" onClick={() => scrollToContact()}>   Let's Connect for your Project </Button>{' '}
                          </ul>
                        </div>
                      </div>
                      <div id="apiSupport" style={{ display: selectedSection === 'apiSupport' ? 'block' : 'none' }}>
                        <h3>Backend Services and APIs</h3>
                        <div className="text">
                          <p>Kloudping specializes in providing backend services and APIs for Angular applications, enabling seamless communication between the frontend and backend systems.</p>

                          <ul className="list-style-one">
                            <li>Angular Backend Integration</li>
                            <li>RESTful API Integration</li>
                            <li>Data Binding with Backend APIs</li>
                            <li>Authentication and Authorization with Backend</li>
                            <Button variant="primary" size="lg" onClick={() => scrollToContact()}>   Let's Connect for your Project </Button>{' '}
                          </ul>
                        </div>
                      </div>
                         <div id="apiSupport" style={{ display: selectedSection === 'apiSupport' ? 'block' : 'none' }}>
                        <h3>Backend Services and APIs</h3>
                        <div className="text">
                          <p>Kloudping specializes in providing backend services and APIs for Angular applications, enabling seamless communication between the frontend and backend systems.</p>

                          <ul className="list-style-one">
                            <li>Angular Backend Integration</li>
                            <li>RESTful API Integration</li>
                            <li>Data Binding with Backend APIs</li>
                            <li>Authentication and Authorization with Backend</li>
                            <Button variant="primary" size="lg" onClick={() => scrollToContact()}>   Let's Connect for your Project </Button>{' '}
                          </ul>
                        </div>
                      </div>
                      <div id="testestingt" style={{ display: selectedSection === 'testing' ? 'block' : 'none' }}>
                        <h3>Testing and Quality Assurance</h3>
                        <div className="text">
                          <p>Comprehensive testing and quality assurance services for Angular applications, ensuring high-performance, bug-free, and user-friendly software solutions.</p>

                          <ul className="list-style-one">
                            <li>Code Review and Quality Assurance</li>
                            <li>Unit Testing</li>
                            <li>Integration Testing</li>
                            <li>Performance Testing</li>
                            <Button variant="primary" size="lg" onClick={() => scrollToContact()}>   Let's Connect for your Project </Button>{' '}
                          </ul>
                        </div>
                      </div>
                      <h3>ASP .Net </h3>
                      <div className="text">
                      <p>Securing your business through software is imperative in today's digital landscape. To fortify your defenses, assess your specific security needs, invest in firewalls, antivirus, and email security software.</p>
                          <p>Encryption is essential for safeguarding sensitive data, and access controls should be implemented to restrict unauthorized entry. Regular software updates, data backups, and an incident response plan are non-negotiable. </p>
                          <p>Employee training and compliance with regulations are vital aspects of your security strategy. Collaborating with IT consultants can provide valuable insights and expertise. Keep in mind that security is an ongoing process; you must regularly review and update your security measures to adapt to evolving cyber threats.</p>
                      </div>

                      <div className="two-col">
                        <div className="row clearfix">
                          <div className="column col-lg-6 col-md-6 col-sm-12">
                            <div className="image"><img src={require('../../images/resource/featured-image-4.jpg')} alt="featured-image-4" /></div>
                          </div>
                          <div className="column col-lg-6 col-md-6 col-sm-12">
                            <div className="image"><img src={require('../../images/resource/featured-image-5.jpg')} alt="featured-image-5" /></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                <aside className="sidebar services-sidebar">

                  <div className="sidebar-widget services-widget">
                    <div className="widget-inner">
                      <ul>
                        <li><a onClick={() => this.scrollToSection('webdevelopment')}>ASP.NET Web Development</a></li>
                        <li><a onClick={() => this.scrollToSection('application')}>.NET Application Modernization</a></li>
                        <li><a onClick={() => this.scrollToSection('ecommerce')}>.NET E-commerce Development</a></li>
                        <li><a onClick={() => this.scrollToSection('apidevelopment')}>.NET API Development</a></li>
                        <li><a onClick={() => this.scrollToSection('microservice')}>.NET Microservices Development</a></li>
                      </ul>
                    </div>
                  </div>

                  <div className="sidebar-widget info-widget">
                    <div className="widget-inner">
                      <div className="image"><a href="/contact"><img src={require('../../images/resource/featured-image-4.jpg')} alt="featured-image-4" /></a></div>
                      <div className="lower">
                        <div className="subtitle">Got any Questions? <br />Call us Today!</div>
                        <div className="icon-box"><span className="flaticon-telephone"></span></div>
                        <div className="phone"><a href="tel:+91 89035 00146">+91 89035 00146</a></div>
                        <div className="email"><a href="mailto:info@kloudping.com">info@kloudping.com</a></div>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>

            </div >
          </div >
        </div >
        <div className="theme-separator"></div>
        <section className="cases-section">
          <div className="gradient-layer"></div>

          <div className="auto-container">
            <div className="sec-title centered">
              <div className="upper-text">Kloudping Advanced Java Technology Stacks</div>

              <div className="lower-text">
                We leverage a comprehensive array of cutting-edge Java technologies to craft customized software solutions, elevating user experiences and driving competitive advantage.
              </div>
            </div>

            <div className="carousel-box">
              <OwlCarousel
                className="cases-carousel theme-carousel owl-theme owl-carousel"
                loop="true"
                margin={30}
                autoheight="true"
                lazyload="true"
                nav="true"
                dots={false}
                autoplay="true"
                autoplayTimeout={7000}
                smartSpeed={700}
                items={3}
              >
                <div className="slide-item">
                  <div className="case-block">
                    <div className="inner-box">
                      <figure className="image-box">
                        <a href="/casestudies">
                          <img
                            src={require("../../images/clients/java.png")}
                            alt="case-image-1"
                            title=""
                            style={{ backgroundBlendMode: "multiply", backgroundColor: "white" }}
                          />
                        </a>
                      </figure>

                    </div>
                  </div>
                </div>

                <div className="slide-item">
                  <div className="case-block">
                    <div className="inner-box">
                      <figure className="image-box">
                        <a href="/casestudies">
                          <img
                            src={require("../../images/clients/spring.png")}
                            alt="case-image-2"
                            title=""
                            style={{ backgroundBlendMode: "multiply", backgroundColor: "white" }}
                          />
                        </a>
                      </figure>

                    </div>
                  </div>
                </div>

                <div className="slide-item">
                  <div className="case-block">
                    <div className="inner-box">
                      <figure className="image-box">
                        <a href="/casestudies">
                          <img
                            src={require("../../images/clients/helidon.png")}
                            alt="case-image-1"
                            title=""
                            style={{ backgroundBlendMode: "multiply", backgroundColor: "white" }}
                          />
                        </a>
                      </figure>

                    </div>
                  </div>
                </div>

                <div className="slide-item">

                  <div className="case-block">
                    <div className="inner-box">
                      <figure className="image-box">
                        <a href="/casestudies">
                          <img
                            src={require("../../images/clients/kafka.png")}
                            alt="case-image-2"
                            title=""
                            className='filter'

                          />
                        </a>
                      </figure>

                    </div>
                  </div>
                </div>


              </OwlCarousel>
            </div>


          </div>

        </section>

        <div className="theme-separator"></div>
        <section className="contact-section-two" id="contact">
          <div className="auto-container">

            <div className="upper-row">
              <div className="row clearfix">

                <div className="text-column col-lg-6 col-md-12 col-sm-12">
                  <div className="inner">
                    <div className="sec-title">
                      <div className="upper-text">Kloudping - Send us a Message</div>
                      <h2>Do You Have Any Questions? We’ll Be Happy To Assist!</h2>
                      <div className="lower-text">We will respond to your inquiry as soon as possible. Right now, we receive many inquiries and it may take a little longer than normal before you get a reply. If you need urgent help, we recommend that you call us during our opening hours..</div>
                    </div>

                    <div className="social-links">
                      <ul className="clearfix">
                        <li><a href="https://www.linkedin.com/company/kloudping" target='_blank'><span className="fab fa-linkedin"></span></a></li>
                        <li><a href=""><span className="fab fa-twitter"></span></a></li>
                        <li><a href="https://www.youtube.com/@Kloudping" target='_blank'><span className="fab fa-youtube"></span></a></li>
                        <li><a href="https://wa.me/+918903500146" target='_blank'><span className="fab fa-whatsapp"></span></a></li>
                        <li><a href=""><span className="fab fa-facebook-square"></span></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="form-column col-lg-6 col-md-12 col-sm-12">
                  <div className="inner">
                    <div className='form-box'>
                      <div className="default-form contact-form">
                        <div className="row clearfix">
                          <h2>Contact Us</h2>
                          <p>Fill out the form and we'll be in touch soon!</p>
                          <div className="col-lg-6 col-md-6 col-sm-12 form-group txtOnly">
                            <input id="name" type="text" name="username" placeholder="Your Name" required="" defaultValue="" />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <input id="email" type="email" name="email" placeholder="Email" />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 form-group numberOnly">
                            <input id="phone" type="text" name="phone" placeholder="Phone" />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <input id="subject" type="text" name="subject" placeholder="Subject" />
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                            <textarea id="message" name="message" placeholder="Message" ></textarea>
                          </div>
                          <div className="form-group col-md-12 col-sm-12">
                            <button type="submit" className="theme-btn btn-style-one"><span className="btn-title" >Make a Request</span></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>


          </div>
        </section>
        <div className="theme-separator"></div>
      </>
    )
  }
}
export default Dotnet
