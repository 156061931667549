import React, { Component } from 'react'
import Back5 from '../../images/background/datainfra.png';
import OwlCarousel from 'react-owl-carousel';
import {Link} from 'react-router-dom'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
class cloudintegration extends Component {
  componentDidMount() {
     }
  render() {
      return (
        <>
        <section className="inner-banner alternate">
                  <div className="image-layer" style={{ background: `url(${Back5})`,backgroundRepeat: "no-repeat",   backgroundPosition: "0% 50%", backgroundSize: "100% 200%" }}></div>
        <div className="auto-container">
            <div className="inner">
                <div className="title-box">
                    <h1>Cloud Integration</h1>
                    <div className="d-text">Building a relationship between IT Services</div>
                </div>
            </div>
        </div>
              </section>
              <div class="sidebar-page-container services-page">
        <div class="auto-container">
            <div class="row clearfix">
                <div class="content-side col-lg-8 col-md-12 col-sm-12">
                    <div class="services-content">
                        <div class="service-details">
                            <div class="image-box">
                                          <img src={require('../../images/resource/service-single-image.jpg')} alt="service-single-image"/>
                                      </div>
                                      <div className="content">
                                <h3>IT Cloud Integration</h3>
                                <div className="text">
                                    <p>Our Cloud Integration services encompass connecting on-premises and cloud-based systems, ensuring a harmonious and efficient IT environment. Key offerings include API integrations, data synchronization, and real-time communication.</p>
                                    <p>We specialize in microservices architecture, leveraging technologies such as Apache Kafka, RabbitMQ, AWS Lambda, and Azure Logic Apps</p>
                                    <p>Our team excels in orchestrating the smooth integration of cloud services, providing clients with scalable, flexible, and interconnected solutions.</p>
                                    
                                          </div>
                                          <div className="two-col">
                                    <div className="row clearfix">
                                        <div className="column col-lg-6 col-md-6 col-sm-12">
                                                      <div className="image"><img src={require('../../images/resource/featured-image-4.jpg')} alt="featured-image-4"/></div>
                                        </div>
                                        <div className="column col-lg-6 col-md-6 col-sm-12">
                                                      <div className="image"><img src={require('../../images/resource/featured-image-5.jpg')} alt="featured-image-5"/></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text">
                                    <h2>Kloudping Comprehensive Cloud Integration Offerings</h2>

                                    <ul className="">
                                        <li><b>Application Integration:</b> Link different software applications to function as a unified system, enhancing overall functionality.</li>
                                        <li><b>Process Integration:</b>Streamline workflows and business processes across cloud environments for improved operational efficiency.</li>
                                        <li><b>Data Integration:</b>Synchronize and share information between systems, ensuring consistent and collaborative data management.</li>
                                        {/* <li>Boat promises something for everyone that this groupfor everyone that this group.</li>
                                        <li>The old somehow form a family that's the way the Love Boat promis something for everyone that this group would somehow form.</li>
                                        <li>The old somehow form a family that's the way to morries.</li>  */}
                                    </ul>
                                   
                                </div>
                                <h2><b>Cloud Integration Tech Stack Expertise</b></h2>
                                    Our team leverages a diverse tech stack, incorporating RESTful APIs, GraphQL, Apache Kafka, RabbitMQ, WebSockets, and Azure Logic Apps. These technologies empower us to deliver robust Cloud Integration solutions, streamlining operations and ensuring optimal interoperability.
                            </div>
                        </div>
                    </div>
                          </div>
                          <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                    <aside className="sidebar services-sidebar">
                
                        <div className="sidebar-widget services-widget">
                            <div className="widget-inner">
                                <ul>
                                    <li><a href='/Devops'>Devops</a></li>
                                    <li className="active"><a href='/cloudintegration'>IT Cloud Integration</a></li>
                                    <li><a href='/Migration'>Cloud Migration</a></li>
                                    <li><a href='/Operation'>Cloud Operation</a></li>
                                    <li><a href='/SAAS'>SaaS Product Development</a></li>
                                </ul>
                            </div>
                        </div>
                        
                                  <div className="sidebar-widget info-widget">
                            <div className="widget-inner">
                                          <div className="image"><Link to="contact"><img src={require('../../images/resource/featured-image-4.jpg')} alt="featured-image-4"/></Link></div>
                                <div className="lower">
                                    <div className="subtitle">Got any Questions? <br/> Call us Today!</div>
                                    <div className="icon-box"><span className="flaticon-telephone"></span></div>
                                    <div className="phone"><a href="tel:(+1)-8903500146">+91 8903500146</a></div>
                                    <div className="email"><a href="mailto:info@kloudping.com">info@kloudping.com</a></div>
                                </div>
                            </div>
                        </div>
                    </aside>
                </div>
                
            </div>
        </div>
    </div>
    <div className="theme-separator"></div>
                </>
                )
            }
        }
export default cloudintegration