import React from 'react'
import { BrowserRouter, Route, Switch } from "react-router-dom"
import SAAS from "./components/IT-Services/SAAS"
import Cloudintegration from "./components/IT-Services/cloudintegration"
import Devops from "./components/IT-Services/Devops"
import Migration from "./components/IT-Services/Migration.jsx"
import MachineLearning from './components/IT-Services/MachineLearning'
import AI from './components/IT-Services/Ai'
import Operation from "./components/IT-Services/Operation"
import Services from "./components/IT-Services/service"
import About from "./components/about/about"
import Casestudies from "./components/about/casestudies"
// import techStack from './components/techStack/techStack'
import FAQ from "./components/about/faq"
import Blog from "./components/blog/bloggrid"
import MAzure from './components/case-studies/Mazure'
import Amlabs from './components/case-studies/amlabs.jsx'
import ProjectDetails from "./components/case-studies/casesingle"
import Machinelearning from './components/case-studies/machinelearning.jsx'
import Footer from "./components/common/footer/footer"
import Header from "./components/common/header/header"
import Contact from "./components/contact/contact"
import ErrorPage from "./components/errorpage"
import Home from "./components/home/home"
import AWS from "./components/techStack/AWS"
import AWSCasestudy from './components/case-studies/AWSCasestudy'
import Android from "./components/techStack/Android"
import Angular from "./components/techStack/Angular"
import Asp from "./components/techStack/Asp"
import Azure from "./components/techStack/Azure"
import BlockChain from "./components/techStack/BlockChain"
import CosmosDB from './components/techStack/CosmosDB'
import Dotnet from "./components/techStack/Dotnet"
import IBM_Stack from "./components/techStack/IBM_Stack"
import Ionic from "./components/techStack/Ionic"
import MS_SQL_Server from "./components/techStack/MS_SQL_Server"
import Microsoft_Stack from "./components/techStack/Microsoft_Stack"
import MongoDB from "./components/techStack/MongoDB"
import MySQL from "./components/techStack/MySQL"
import Nodejs from "./components/techStack/Nodejs"
import Odoo from "./components/techStack/Odoo"
import Oracle from "./components/techStack/Oracle"
import PHP from "./components/techStack/PHP"
import PostgresSQL from "./components/techStack/PostgresSQL"
import Python from "./components/techStack/Python"
import ReactJs from "./components/techStack/ReactJs"
import SAS from "./components/techStack/SAS"
import Tableau from "./components/techStack/Tableau"
import iOS from "./components/techStack/iOS"
import java from "./components/techStack/java"
import privacypolicy from './components/common/policy/privacy-policy.jsx'
import MyChatbot from './components/chatbot/Mychatbot.jsx' 
import "./css/bootstrap.css"
import "./css/faq.css"
import "./css/responsive.css"
import "./css/style.css"

const App = () => (


  <BrowserRouter >

    <div className="page-wrapper">
  
  <MyChatbot />
  <Header />

      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/about' component={About} />
        <Route exact path='/contact' component={Contact} />
        <Route exact path='/blog' component={Blog} />
        <Route exact path='/casestudies' component={Casestudies} />
        {/* <Route exact path='/techStack' component={techStack} /> */}
        <Route exact path='/projectdetails' component={ProjectDetails} />
        <Route exact path='/faq' component={FAQ} />
        <Route exact path='/services' component={Services} />
        <Route exact path='/Devops' component={Devops} />
        <Route exact path='/cloudintegration' component={Cloudintegration} />
        <Route exact path='/Migration' component={Migration} />
        <Route exact path='/MachineLearning' component={MachineLearning} />
        <Route exact path='/AI' component={AI} />
        <Route exact path='/Operation' component={Operation} />
        <Route exact path='/SAAS' component={SAAS} />
        <Route exact path='/java' component={java} />
        <Route exact path='/Android' component={Android} />
        <Route exact path='/Angular' component={Angular} />
        <Route exact path='/Asp' component={Asp} />
        <Route exact path='/AWS' component={AWS} />
        <Route exact path='/AwsCasestudy' component={AWSCasestudy} />
        <Route exact path='/Azure' component={Azure} />
        <Route exact path='/MAzure' component={MAzure} />
        <Route exact path='/Amlabs' component={Amlabs} />
        <Route exact path='/Machinelearning' component={Machinelearning} />
        <Route exact path='/BlockChain' component={BlockChain} />
        <Route exact path='/Dotnet' component={Dotnet} />
        <Route exact path='/IBM_Stack' component={IBM_Stack} />
        <Route exact path='/Ionic' component={Ionic} />
        <Route exact path='/iOS' component={iOS} />
        <Route exact path='/Microsoft_Stack' component={Microsoft_Stack} />
        <Route exact path='/MS_SQL_Server' component={MS_SQL_Server} />
        <Route exact path='/CosmosDB' component={CosmosDB} />
        <Route exact path='/MySQL' component={MySQL} />
        <Route exact path='/Nodejs' component={Nodejs} />
        <Route exact path='/Odoo' component={Odoo} />
        <Route exact path='/Oracle' component={Oracle} />
        <Route exact path='/PHP' component={PHP} />
        <Route exact path='/PostgresSQL' component={PostgresSQL} />
        <Route exact path='/MongoDB' component={MongoDB} />
        <Route exact path='/Python' component={Python} />
        <Route exact path='/ReactJs' component={ReactJs} />
        <Route exact path='/SAS' component={SAS} />
        <Route exact path='/Tableau' component={Tableau} />
        <Route exact path='/privacypolicy' component={privacypolicy} />
        <Route exact path='*' component={ErrorPage} />
      </Switch>
      <Footer />
    </div>
  </BrowserRouter >


)

export default App
