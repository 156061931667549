import React, { Component } from 'react'
import Back8 from '../../images/background/banner-bg-1.jpg'
import Button from 'react-bootstrap/Button';
import {Link} from 'react-router-dom'



function scrollToContact() {
    const contactSection = document.getElementById('contact');
    if (contactSection) {
        contactSection.scrollIntoView({ behavior: 'smooth' });
    }
}

class AWSCasestudy extends Component {
    componentDidMount() {
    }
    render() {
        return (
            <>
                <section className="inner-banner alternate">
                    <div className="image-layer" style={{ background: `url(${Back8})` }}></div>
                    <div className="auto-container">
                        <div className="inner">
                            <div className="title-box">
                                <h1>AWS</h1>
                                <div className="d-text">Building a relationship between IT Services</div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="sidebar-page-container services-page">
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="content-side col-lg-8 col-md-12 col-sm-12">
                                <div className="services-content">
                                    <div className="service-details">
                                        <div className="image-box">
                                            <img src={require('../../images/resource/service-single-image.jpg')} alt="service-single-image" />
                                        </div>
                                        <div className="content">
                                            <h3>AWS CaseStudy</h3>
                                            <div className="text">
                                                <h4>How to create AWS resources using Terraform, a popular infrastructure as code tool.</h4>
                                                <p>Terraform is compatible with AWS and other major cloud providers, and it allows you to define your infrastructure using a declarative syntax that is easy to read and write.</p>
                                                <p>To get started, you will need to have an AWS account. You will also need to install the AWS CLI and the Terraform CLI on your local machine. Once you have these prerequisites, you can follow these steps to create your AWS resources using Terraform:</p>
                                                <p>Terraform is a popular tool for managing infrastructure as code on various cloud platforms, including AWS. In this blog post, I will show you how to install Terraform on your local machine and configure it to work with AWS.</p>
                                                <p>The first step is to download the latest version of Terraform from the <a href="https://www.terraform.io/downloads.html." target='_blank'>official Website. </a> You can choose the binary for your operating system and architecture. For example, if you are using a 64-bit Windows machine, you can </p>
                                                 <div className="more-link"><a href="" className="theme-btn btn-style-one"><span className="btn-title" >Learn More</span></a></div>
                                            </div>

                                            <div className="two-col">
                                                <div className="row clearfix">
                                                    <div className="column col-lg-6 col-md-6 col-sm-12">
                                                        <div className="image"><img src={require('../../images/resource/featured-image-4.jpg')} alt="featured-image-4" /></div>
                                                    </div>
                                                    <div className="column col-lg-6 col-md-6 col-sm-12">
                                                        <div className="image"><img src={require('../../images/resource/featured-image-5.jpg')} alt="featured-image-5" /></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h3>AWS Enterprise Solutions</h3>
                                            <div className="text">
                                                <p>Kloudping provides comprehensive Java enterprise solutions, enabling businesses to build scalable and secure applications that meet the complex demands of enterprise-level environments. Our Java experts leverage cutting-edge technologies and frameworks to deliver robust and reliable enterprise solutions.</p>

                                                <ul className="list-style-one">
                                                    <li>Scalable and secure applications for enterprise</li>
                                                    <li>Cutting-edge Java technologies and frameworks</li>
                                                    <li>Robust and reliable solutions</li>
                                                    <li>Enterprise-level Java solutions</li>
                                                    <Button variant="primary" size="lg" onClick={() => scrollToContact()}>   Let's Connect for your Project </Button>{' '}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                                <aside className="sidebar services-sidebar">

                                    <div className="sidebar-widget services-widget">
                                        <div className="widget-inner">
                                            <ul>
                                                <li><a href='/Devops'>Data Infrastructure</a></li>
                                                <li><a href='/cloudintegration' >IT Cloud Integration</a></li>
                                                <li><a href='/Migration'>IT Startup Projects</a></li>
                                                <li><a href='/productengineering'>Product Engineering</a></li>
                                                <li className="active"><a href='/businesssecurity' >Business Security</a></li>
                                            </ul>
                                        </div>
                                    </div>

                                    

                                    <div className="sidebar-widget info-widget">
                                        <div className="widget-inner">
                                            <div className="image"><Link to="contact"><img src={require('../../images/resource/featured-image-4.jpg')} alt="featured-image-4" /></Link></div>
                                            <div className="lower">
                                                <div className="subtitle">Got any Questions? <br />Call us Today!</div>
                                                <div className="icon-box"><span className="flaticon-telephone"></span></div>
                                                <div className="phone"><a href="tel:+91 89035 00146">+91 89035 00146</a></div>
                                                <div className="email"><a href="mailto:info@kloudping.com">info@kloudping.com</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </aside>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="theme-separator"></div>
                <section className="contact-section-two" id="contact">
                    <div className="auto-container">

                        <div className="upper-row">
                            <div className="row clearfix">

                                <div className="text-column col-lg-6 col-md-12 col-sm-12">
                                    <div className="inner">
                                        <div className="sec-title">
                                            <div className="upper-text">Kloudping - Send us a Message</div>
                                            <h2>Do You Have Any Questions? We’ll Be Happy To Assist!</h2>
                                            <div className="lower-text">We will respond to your inquiry as soon as possible. Right now, we receive many inquiries and it may take a little longer than normal before you get a reply. If you need urgent help, we recommend that you call us during our opening hours..</div>
                                        </div>

                                        <div className="social-links">
                                            <ul className="clearfix">
                                                <li><a href="https://www.linkedin.com/company/kloudping" target='_blank'><span className="fab fa-linkedin"></span></a></li>
                                                <li><a href="#"><span className="fab fa-twitter"></span></a></li>
                                                <li><a href="https://www.youtube.com/@Kloudping" target='_blank'><span className="fab fa-youtube"></span></a></li>
                                                <li><a href="https://wa.me/+918903500146" target='_blank'><span className="fab fa-whatsapp"></span></a></li>
                                                <li><a href="#"><span className="fab fa-facebook-square"></span></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-column col-lg-6 col-md-12 col-sm-12">
                                    <div className="inner">
                                        <div className='form-box'>
                                            <div className="default-form contact-form">
                                                <div className="row clearfix">
                                                    <h2>Contact Us</h2>
                                                    <p>Fill out the form and we'll be in touch soon!</p>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 form-group txtOnly">
                                                        <input id="name" type="text" name="username" placeholder="Your Name" required="" defaultValue="" />
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                        <input id="email" type="email" name="email" placeholder="Email" />
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 form-group numberOnly">
                                                        <input id="phone" type="text" name="phone" placeholder="Phone" />
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                        <input id="subject" type="text" name="subject" placeholder="Subject" />
                                                    </div>
                                                    <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                                        <textarea id="message" name="message" placeholder="Message" ></textarea>
                                                    </div>
                                                    <div className="form-group col-md-12 col-sm-12">
                                                        <button type="submit" className="theme-btn btn-style-one"><span className="btn-title" >Make a Request</span></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </section>

                <div className="theme-separator"></div>
            </>
        )
    }
}
export default AWSCasestudy
