import { ColorPicker } from '@grapecity/wijmo.react.input';
import React from 'react'
import "../Bot/botAvatar";

export default function DipslayImage(props) {
    const {name, imageUrl, link } = props.state.userData.product;
    console.log(props.state.userData)

    return (
        <div className='img-component'>
            <div className='img-container'>
                <p className='image'>{name}</p>
                <img src={imageUrl} alt="" />
            </div>
            <a href={link} target='_blank' rel="noreferrer" className='link'>Kloudping LinkedIn</a>
        </div>
    )
}