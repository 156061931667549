import React, { Component } from "react";
import $ from "jquery";
import "../header/header.css";
import { Link } from "react-router-dom"


import Dropdown from "react-bootstrap/Dropdown";

class header extends Component {
  constructor() {
    super();
    this.state = {
      showSubMenu: false,
    };
  }

  toggleSubMenu = () => {
    this.setState({ showSubMenu: !this.state.showSubMenu });
  };

  componentDidMount() {
    function headerStyle() {
      if ($(".main-header").length) {
        var windowpos = $(window).scrollTop();
        var siteHeader = $(".main-header");
        var scrollLink = $(".scroll-to-top");
        var sticky_header = $(".main-header .sticky-header");
        if (windowpos > 180) {
          siteHeader.addClass("fixed-header");
          sticky_header.addClass("animated slideInDown");
          scrollLink.fadeIn(300);
        } else {
          siteHeader.removeClass("fixed-header");
          sticky_header.removeClass("animated slideInDown");
          scrollLink.fadeOut(300);
        }
      }
    }

    headerStyle();
    //Submenu Dropdown Toggle
    if ($(".main-header li.dropdown ul").length) {
      $(".main-header .navigation li.dropdown").append(
        '<div class="dropdown-btn"><span class="fa fa-angle-right"></span></div>'
      );
    }
    //Mobile Nav Hide Show
    if ($(".mobile-menu").length) {
      //$('.mobile-menu .menu-box').mCustomScrollbar();

      var mobileMenuContent = $(".main-header .nav-outer .main-menu").html();
      $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);
      $(".sticky-header .main-menu").append(mobileMenuContent);

      //Dropdown Button
      $(".mobile-menu li.dropdown .dropdown-btn").on("click", function () {
        $(this).toggleClass("open");
        $(this).prev("ul").slideToggle(500);
      });
      //Menu Toggle Btn
      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });

      //Menu Toggle Btn
      $(".mobile-menu .menu-backdrop,.mobile-menu .close-btn").on(
        "click",
        function () {
          $("body").removeClass("mobile-menu-visible");
        }
      );
    }
    $(window).on("scroll", function () {
      headerStyle();
    });

    var a = document.querySelectorAll(".navigation a");
    for (var i = 0, length = a.length; i < length; i++) {
      a[i].onclick = function () {
        var b = document.querySelector(".navigation li.active");
        if (b) b.classList.remove("active");
        this.parentNode.classList.add("active");
      };
    }
  }

  render() {
    return (
      <>
        <header className="main-header header-style-one">
          <div className="header-top header-top-one">
            <div className="auto-container">
              <div className="inner clearfix">
                <div className="top-left clearfix">
                  <div className="top-text">
                    IT solutions that are designed to integrate multiple sectors
                  </div>
                </div>

                <div className="top-right clearfix">
                  <div className="info">
                    <ul className="clearfix">
                      <li className="phone">
                        <a href="tel:+91 89035 00146">
                          <span className="icon sl-icon-call-in"></span>
                          <strong>+91 89035 00146</strong>
                        </a>
                      </li>
                      <li className="email">
                        <span className="icon sl-icon-envelope-open"></span>
                        <a href="mailto:&#105;&#110;&#102;&#111;&#064;&#107;&#108;&#111;&#117;&#100;&#112;&#105;&#110;&#103;&#046;&#099;&#111;&#109;">
                        <strong>info@kloudping.com</strong>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="header-upper">
            <div className="auto-container">
              <div className="inner-container clearfix">
                <div className="logo-box">
                  <div className="logo">
                    <a
                      href="/"
                      title="Kloudping - Business and Consulting HTML Template"
                    >
                      <img className="logo"
                        src={require("../../../images/logo.png")}
                        alt="Kloudping - Business and Consulting HTML Template"
                        title="Kloudping - Business and Consulting HTML Template"
                      />
                    </a>
                  </div>
                </div>
                <div className="right-nav clearfix">
                  <div className="nav-outer clearfix">
                    <div className="mobile-nav-toggler">
                      <span className="icon flaticon-menu-1"></span>
                    </div>

                    <nav className="main-menu navbar-expand-md navbar-light">
                      <div
                        className="collapse navbar-collapse show clearfix"
                        id="navbarSupportedContent"
                      >
                        <ul className="navigation clearfix">
                          <li className="dropdown">
                            <Link to="/">Home</Link>
                          </li>
                          
                          <li className="dropdown">
                            <Link to="Services">Services</Link>
                            <ul>
                              <li>
                                <Link to="Devops">
                                DevOps
                                </Link>
                              </li>
                              <li>
                                <Link to="Migration">
                                Cloud Migration
                                </Link>
                              </li>
                              <li>
                                <Link to="Cloudintegration">Cloud Integration</Link>
                              </li>
                              <li>
                                <Link to="Operation">
                                Cloud Operation
                                </Link>
                              </li>
                              <li>
                                <Link to="SAAS">
                                SAAS Product Development
                                </Link>
                              </li>
                              
                            </ul>
                          </li>
                         
                          {/* <li className="dropdown">
                              <Link to="techStack">Tech Stack</Link>
                              <ul>
                                <li className="dropdown">
                                  <Link to="Casestudies">Web Development</Link>
                                  <ul>
                                    <li>
                                    <Link to="java">Java</Link>
                                    </li>
                                    <li>
                                      <Link to="PHP">PHP</Link>
                                    </li>
                                    <li>
                                      <Link to="Angular">Angular</Link>
                                    </li>
                                    <li>
                                      <Link to="Dotnet">Dotnet</Link>
                                    </li>
                                    <li>
                                      <Link to="ReactJs">React</Link>
                                    </li>
                                    <li>
                                      <Link to="Python">Python</Link>
                                    </li>
                                    <li>
                                      <Link to="Nodejs">NodeJs</Link>
                                    </li>
                                  </ul>
                                </li>
                                <li className="dropdown">
                                  <Link to="Casestudies">Cloud Service</Link>
                                  <ul>
                                    <li>
                                      <Link to="Azure">MicroSoft Azure</Link>
                                    </li>
                                    <li>
                                      <Link to="AWS">AWS</Link>
                                    </li>
                                  </ul>
                                </li>
                                <li className="dropdown">
                                  <Link to="Casestudies">DataBase</Link>
                                  <ul>
                                    <li>
                                      <Link to="techStack">Oracle</Link>
                                    </li>
                                    <li>
                                      <Link to="techStack">MySQL</Link>
                                    </li>
                                    <li>
                                      <Link to="techStack">PostgresSQL</Link>
                                    </li>
                                    <li>
                                      <Link to="techStack">MongoDB</Link>
                                    </li>
                                    <li>
                                      <Link to="techStack">Cosmos DB</Link>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </li> */}
                         
                            <li className="dropdown">

                            <Link to="Casestudies">Case Study</Link>
                            <ul>
                            <li className="dropdown">
                                  <Link to="Casestudies">Case Study</Link>
                                  <ul>
                                    <li>
                                      <Link to="AWSCasestudy">AWS</Link>
                                    </li>
                                    <li>
                                      <Link to="Mazure">Azure</Link>
                                    </li>
                                  </ul>
                                </li>
                              <li>
                                <Link to="ProjectDetails">Project Details</Link>
                              </li>
                            </ul>
                            </li>

                         
                          <li className="dropdown ">
                            <Link to="about">About Us</Link>
                            <ul>
                              <li>
                                <Link to="FAQ">FAQ's</Link>
                              </li>
                            </ul>
                          </li>

                          <li className="dropdown">
                            <Link to="Blog">Blog</Link>
                          </li>

                          <li className="dropdown">
                            <Link to="contact">Contact Us</Link>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sticky-header">
            <div className="auto-container clearfix">
              <div className="logo pull-left" style={{width:"21%",marginBottom:"1%",paddingTop:"0%"}}>
                <Link to="" title="">
                  <img className="logo"
                    src={require("../../../images/logo.png")}
                    alt="sticky-logo"
                    title=""
                  />
                </Link>
              </div>

              <div className="pull-right">
                <nav className="main-menu clearfix"></nav>
              </div>
            </div>
          </div>
          <div className="mobile-menu">
            <div className="menu-backdrop"></div>
            <div className="close-btn">
              <span className="icon flaticon-targeting-cross"></span>
            </div>

            <nav className="menu-box">
              <div className="nav-logo">
                <Link to="">
                  <img className="logo"
                    src={require("../../../images/sidenav.jpg")}
                    alt="nav-logo"
                    title=""
                  />
                </Link>
              </div>
              <div className="menu-outer"></div>

              <div className="social-links">
                <ul className="clearfix">
                  <li>
                    <a href="https://twitter.com/kloudping">
                      <span className="fab fa-twitter"></span>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/profile.php?id=100094700390666">
                      <span className="fab fa-facebook-square"></span>
                    </a>
                  </li>
                  <li>
                    <Link to="">
                      <span className="fab fa-pinterest-p"></span>
                    </Link>
                  </li>
                  <li>
                    <a href="https://wa.me/+918903500146" target="_blank">
                      <span className="fab fa-whatsapp"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/@Kloudping"
                      target="_blank"
                    >
                      <span className="fab fa-youtube"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>
      </>
    );
  }
}

export default header;
