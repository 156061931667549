
import React from 'react';
import Back1 from '../images/404-error-404-not-3840x2160-9410.jpg';
import {Link} from 'react-router-dom'

class errorpage extends React.Component {
    render() {
 
        return (
    <div  >
          <img src={Back1}  />
           <Link to="">  <p style={{textAlign:"center"}}> Go to Home </p></Link>
           
    </div>
)}}
  
export default errorpage;