import React, { Component } from 'react'
import Back8 from '../../images/background/banner-bg-1.jpg'
import CountUp from "react-countup";
import Button from 'react-bootstrap/Button';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useState } from 'react';
import WOW from "wowjs";

function scrollToContact() {
  const contactSection = document.getElementById('contact');
  if (contactSection) {
    contactSection.scrollIntoView({ behavior: 'smooth' });
  }
}
const scrollToSection = (event, sectionId) => {
  event.preventDefault();
  const element = document.getElementById(sectionId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

class Angular extends Component {


  state = {
    selectedSection: 'application',
  };

  scrollToSection = (sectionId) => {
    this.setState({ selectedSection: sectionId });
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  componentDidMount() {

  }

  constructor(props) {
    super(props);
    this.state = {
      content: 'application', // Initial content
    };
  }

  changeContent = (newContent) => {
    this.setState({ content: newContent });
  };


  render() {
    const { selectedSection } = this.state;
    return (
      <>
        <section className="inner-banner alternate">
          <div className="image-layer" style={{ background: `url(${Back8})` }}></div>
          <div className="auto-container">
            <div className="inner">
              <div className="title-box">
                <h1>Hire Experienced Angular Developers</h1>
              </div>
            </div>
          </div>

        </section>
        <div className="sidebar-page-container services-page">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="content-side col-lg-8 col-md-12 col-sm-12">
                <div className="services-content">
                  <div className="service-details">
                    <div className="content">
                      <div id="application" style={{ display: selectedSection === 'application' ? 'block' : 'none' }}>
                        <h3>Angular Web Application Development</h3>
                        <div className="text">
                          <p>Kloudping specializes in web application development, crafting tailored solutions with seamless functionality, scalability, and security to deliver high-quality web applications that meet your unique requirements.</p>

                          <ul className="list-style-one">
                            <li>Front-End Design and Development</li>
                            <li>Web App Maintenance and Support</li>
                            <li>Responsive Angular Web Development</li>
                            <li>Angular Component Development</li>
                            <Button variant="primary" size="lg" onClick={() => scrollToContact()}>   Let's Connect for your Project </Button>{' '}
                          </ul>
                        </div>
                      </div>
                      <div id="application" style={{ display: selectedSection === 'application' ? 'block' : 'none' }}>
                        <h3>Angular Web Application Development</h3>
                        <div className="text">
                          <p>Kloudping offers Java mobile app development services, building feature-rich and reliable mobile applications using the Java programming language. Our developers leverage Java frameworks and technologies to deliver engaging and high-performing mobile solutions.</p>

                          <ul className="list-style-one">
                            <li>Innovative and user-friendly mobile apps</li>
                            <li>Java frameworks for enhanced functionality</li>
                            <li>High-performing mobile solutions</li>
                            <li>Delivering engaging user experiences</li>
                            <Button variant="primary" size="lg" onClick={() => scrollToContact()}>   Let's Connect for your Project </Button>{' '}
                          </ul>
                        </div>
                      </div>
                      <div id="singlepageapplication" style={{ display: selectedSection === 'singlepageapplication' ? 'block' : 'none' }}>
                        <h3>Single Page Application Development</h3>
                        <div className="text">
                          <p>Kloudping excels in Angular single page application (SPA) development, leveraging the power of Angular framework to create dynamic and interactive web applications.</p>

                          <ul className="list-style-one">
                            <li>Angular SPA Design and Development</li>
                            <li>Angular SPA UI/UX Design and Implementation</li>
                            <li>Angular Routing and Navigation for SPAs</li>
                            <li>Angular SPA Testing and Quality Assurance</li>
                            <Button variant="primary" size="lg" onClick={() => scrollToContact()}>   Let's Connect for your Project </Button>{' '}
                          </ul>
                        </div>
                      </div>
                      <div id="ui/ux" style={{ display: selectedSection === 'ui/ux' ? 'block' : 'none' }}>
                        <h3>UI/UX Design and Development</h3>
                        <div className="text">
                          <p>Our expert team combines creative design skills with technical expertise in UI/UX design and development, creating visually appealing and user-friendly interfaces that enhance the overall user experience.
                          </p>

                          <ul className="list-style-one">
                            <li>Angular User Interface Design</li>
                            <li>Angular User Experience Design</li>
                            <li>Angular Responsive Design Implementation</li>
                            <li>Angular UI/UX Testing and Iteration</li>
                            <Button variant="primary" size="lg" onClick={() => scrollToContact()}>   Let's Connect for your Project </Button>{' '}
                          </ul>
                        </div>
                      </div>
                      <div id="frontend" style={{ display: selectedSection === 'frontend' ? 'block' : 'none' }}>
                        <h3>Angular Front-end Development Services</h3>
                        <div className="text">
                          <p>Kloudping Angular experts develop single-page application because Angular places a premium on code readability and configurability.</p>
                          <ul className="list-style-one">
                            <Button variant="primary" size="lg" onClick={() => scrollToContact()}>   Let's Connect for your Project </Button>{' '}
                          </ul>
                        </div>
                      </div>
                      <div id="apiSupport" style={{ display: selectedSection === 'apiSupport' ? 'block' : 'none' }}>
                        <h3>Backend Services and APIs</h3>
                        <div className="text">
                          <p>Kloudping specializes in providing backend services and APIs for Angular applications, enabling seamless communication between the frontend and backend systems.</p>

                          <ul className="list-style-one">
                            <li>Angular Backend Integration</li>
                            <li>RESTful API Integration</li>
                            <li>Data Binding with Backend APIs</li>
                            <li>Authentication and Authorization with Backend</li>
                            <Button variant="primary" size="lg" onClick={() => scrollToContact()}>   Let's Connect for your Project </Button>{' '}
                          </ul>
                        </div>
                      </div>
                         <div id="apiSupport" style={{ display: selectedSection === 'apiSupport' ? 'block' : 'none' }}>
                        <h3>Backend Services and APIs</h3>
                        <div className="text">
                          <p>Kloudping specializes in providing backend services and APIs for Angular applications, enabling seamless communication between the frontend and backend systems.</p>

                          <ul className="list-style-one">
                            <li>Angular Backend Integration</li>
                            <li>RESTful API Integration</li>
                            <li>Data Binding with Backend APIs</li>
                            <li>Authentication and Authorization with Backend</li>
                            <Button variant="primary" size="lg" onClick={() => scrollToContact()}>   Let's Connect for your Project </Button>{' '}
                          </ul>
                        </div>
                      </div>
                      <div id="testestingt" style={{ display: selectedSection === 'testing' ? 'block' : 'none' }}>
                        <h3>Testing and Quality Assurance</h3>
                        <div className="text">
                          <p>Comprehensive testing and quality assurance services for Angular applications, ensuring high-performance, bug-free, and user-friendly software solutions.</p>

                          <ul className="list-style-one">
                            <li>Code Review and Quality Assurance</li>
                            <li>Unit Testing</li>
                            <li>Integration Testing</li>
                            <li>Performance Testing</li>
                            <Button variant="primary" size="lg" onClick={() => scrollToContact()}>   Let's Connect for your Project </Button>{' '}
                          </ul>
                        </div>
                      </div>
                      <h3>Angular </h3>
                      <div className="text">
                      <p>Securing your business through software is imperative in today's digital landscape. To fortify your defenses, assess your specific security needs, invest in firewalls, antivirus, and email security software.</p>
                          <p>Encryption is essential for safeguarding sensitive data, and access controls should be implemented to restrict unauthorized entry. Regular software updates, data backups, and an incident response plan are non-negotiable. </p>
                          <p>Employee training and compliance with regulations are vital aspects of your security strategy. Collaborating with IT consultants can provide valuable insights and expertise. Keep in mind that security is an ongoing process; you must regularly review and update your security measures to adapt to evolving cyber threats.</p>
                      </div>

                      <div className="two-col">
                        <div className="row clearfix">
                          <div className="column col-lg-6 col-md-6 col-sm-12">
                            <div className="image"><img src={require('../../images/resource/featured-image-4.jpg')} alt="featured-image-4" /></div>
                          </div>
                          <div className="column col-lg-6 col-md-6 col-sm-12">
                            <div className="image"><img src={require('../../images/resource/featured-image-5.jpg')} alt="featured-image-5" /></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                <aside className="sidebar services-sidebar">

                  <div className="sidebar-widget services-widget">
                    <div className="widget-inner">
                      <ul>
                        <li><a onClick={() => this.scrollToSection('application')}>Web Application Development</a></li>
                        <li><a onClick={() => this.scrollToSection('singlepageapplication')}>SinglePage Application Development</a></li>
                        <li><a onClick={() => this.scrollToSection('ui/ux')}>UI/UX Design and Development</a></li>
                        <li><a onClick={() => this.scrollToSection('frontend')}>Front-end Development Services</a></li>
                        <li><a onClick={() => this.scrollToSection('apiSupport')}>Backend Services and APIs</a></li>
                        <li><a onClick={() => this.scrollToSection('testing')}>Testing and Quality Assurance</a></li>
                      </ul>
                    </div>
                  </div>

                  <div className="sidebar-widget info-widget">
                    <div className="widget-inner">
                      <div className="image"><a href="/contact"><img src={require('../../images/resource/featured-image-4.jpg')} alt="featured-image-4" /></a></div>
                      <div className="lower">
                        <div className="subtitle">Got any Questions? <br />Call us Today!</div>
                        <div className="icon-box"><span className="flaticon-telephone"></span></div>
                        <div className="phone"><a href="tel:+91 89035 00146">+91 89035 00146</a></div>
                        <div className="email"><a href="mailto:info@kloudping.com">info@kloudping.com</a></div>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>

            </div >
          </div >
        </div >
        <div className="theme-separator"></div>
        <section className="cases-section">
          <div className="gradient-layer"></div>

          <div className="auto-container">
            <div className="sec-title centered">
              <div className="upper-text">Kloudping Advanced Java Technology Stacks</div>

              <div className="lower-text">
                We leverage a comprehensive array of cutting-edge Java technologies to craft customized software solutions, elevating user experiences and driving competitive advantage.
              </div>
            </div>

            <div className="carousel-box">
              <OwlCarousel
                className="cases-carousel theme-carousel owl-theme owl-carousel"
                loop="true"
                margin={30}
                autoheight="true"
                lazyload="true"
                nav="true"
                dots={false}
                autoplay="true"
                autoplayTimeout={7000}
                smartSpeed={700}
                items={3}
              >
                <div className="slide-item">
                  <div className="case-block">
                    <div className="inner-box">
                      <figure className="image-box">
                        <a href="/casestudies">
                          <img
                            src={require("../../images/clients/java.png")}
                            alt="case-image-1"
                            title=""
                            style={{ backgroundBlendMode: "multiply", backgroundColor: "white" }}
                          />
                        </a>
                      </figure>

                    </div>
                  </div>
                </div>

                <div className="slide-item">
                  <div className="case-block">
                    <div className="inner-box">
                      <figure className="image-box">
                        <a href="/casestudies">
                          <img
                            src={require("../../images/clients/spring.png")}
                            alt="case-image-2"
                            title=""
                            style={{ backgroundBlendMode: "multiply", backgroundColor: "white" }}
                          />
                        </a>
                      </figure>

                    </div>
                  </div>
                </div>

                <div className="slide-item">
                  <div className="case-block">
                    <div className="inner-box">
                      <figure className="image-box">
                        <a href="/casestudies">
                          <img
                            src={require("../../images/clients/helidon.png")}
                            alt="case-image-1"
                            title=""
                            style={{ backgroundBlendMode: "multiply", backgroundColor: "white" }}
                          />
                        </a>
                      </figure>

                    </div>
                  </div>
                </div>

                <div className="slide-item">

                  <div className="case-block">
                    <div className="inner-box">
                      <figure className="image-box">
                        <a href="/casestudies">
                          <img
                            src={require("../../images/clients/kafka.png")}
                            alt="case-image-2"
                            title=""
                            className='filter'

                          />
                        </a>
                      </figure>

                    </div>
                  </div>
                </div>


              </OwlCarousel>
            </div>


          </div>

        </section>

        <div className="theme-separator"></div>
        <section className="contact-section-two" id="contact">
          <div className="auto-container">

            <div className="upper-row">
              <div className="row clearfix">

                <div className="text-column col-lg-6 col-md-12 col-sm-12">
                  <div className="inner">
                    <div className="sec-title">
                      <div className="upper-text">Kloudping - Send us a Message</div>
                      <h2>Do You Have Any Questions? We’ll Be Happy To Assist!</h2>
                      <div className="lower-text">We will respond to your inquiry as soon as possible. Right now, we receive many inquiries and it may take a little longer than normal before you get a reply. If you need urgent help, we recommend that you call us during our opening hours..</div>
                    </div>

                    <div className="social-links">
                      <ul className="clearfix">
                        <li><a href="https://www.linkedin.com/company/kloudping" target='_blank'><span className="fab fa-linkedin"></span></a></li>
                        <li><a href=""><span className="fab fa-twitter"></span></a></li>
                        <li><a href="https://www.youtube.com/@Kloudping" target='_blank'><span className="fab fa-youtube"></span></a></li>
                        <li><a href="https://wa.me/+918903500146" target='_blank'><span className="fab fa-whatsapp"></span></a></li>
                        <li><a href=""><span className="fab fa-facebook-square"></span></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="form-column col-lg-6 col-md-12 col-sm-12">
                  <div className="inner">
                    <div className='form-box'>
                      <div className="default-form contact-form">
                        <div className="row clearfix">
                          <h2>Contact Us</h2>
                          <p>Fill out the form and we'll be in touch soon!</p>
                          <div className="col-lg-6 col-md-6 col-sm-12 form-group txtOnly">
                            <input id="name" type="text" name="username" placeholder="Your Name" required="" defaultValue="" />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <input id="email" type="email" name="email" placeholder="Email" />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 form-group numberOnly">
                            <input id="phone" type="text" name="phone" placeholder="Phone" />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <input id="subject" type="text" name="subject" placeholder="Subject" />
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                            <textarea id="message" name="message" placeholder="Message" ></textarea>
                          </div>
                          <div className="form-group col-md-12 col-sm-12">
                            <button type="submit" className="theme-btn btn-style-one"><span className="btn-title" >Make a Request</span></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>


          </div>
        </section>
        <div className="theme-separator"></div>
      </>
    )
  }
}
export default Angular
