import React, { Component } from 'react'
import Back6 from '../../images/background/banner-bg-1.jpg';
import {Link} from 'react-router-dom'
class Migration extends Component {
  componentDidMount() {
     }
  render() {
      return (
        <>
        <section className="inner-banner alternate">
        <div className="image-layer" style={{ background: `url(${Back6})`}}></div>
        <div className="auto-container">
            <div className="inner">
                <div className="title-box">
                    <h1>Cloud Migration</h1>
                    <div className="d-text">Building a relationship between IT Services</div>
                </div>
            </div>
        </div>
          </section>
          <div className="sidebar-page-container services-page">
        <div className="auto-container">
            <div className="row clearfix">
                
                
                <div className="content-side col-lg-8 col-md-12 col-sm-12">
                    <div className="services-content">
                        <div className="service-details">
                            <div className="image-box">
                        <img src={require('../../images/resource/service-single-image.jpg')} alt="service-single-image"/>
                            </div>
                           
                            <div className="content">
                                <h3>Cloud Migration</h3>
                                <div className="text">
                                    <p>Embark on a successful Cloud Migration journey us, where we provide comprehensive migration services, including infrastructure, data, application, and platform migration. Our approach involves careful planning, consideration of security and compliance requirements, and ongoing management to optimize the cloud environment, meeting evolving business needs. 

</p>{/* <ul  className="list-style-one"><li>
Customer Relationship Management Systems (CRMS)
    </li>
    <li>Human Resource Management Systems (HRMS)</li>
    <li>Robo Trader Hub</li>
    <li>Cloud Migration</li>
    <li>Cloud Secuirty</li>
    <li>DevOps</li>
    </ul> */}
            

                                </div>
                                
                                <div className="two-col">
                                    <div className="row clearfix">
                                        <div className="column col-lg-6 col-md-6 col-sm-12">
                              <div className="image"><img src={require('../../images/resource/featured-image-4.jpg')} alt="featured-image-4"/></div>
                                        </div>
                                        <div className="column col-lg-6 col-md-6 col-sm-12">
                                            <div className="image"><img src={require('../../images/resource/featured-image-5.jpg')} alt="featured-image-5"/></div>
                                        </div>
                                    </div>
                                </div>
                                <h3>Kloudping Comprehensive Cloud Integration Offerings</h3>
                                <div className="text">
                                    <p><b>Infrastructure Migration:</b></p>
<p>Efficiently migrating applications and data to the cloud with minimal disruption for a swift transition.</p>
                                </div>
                                <div className="text">
                                    <p><b>Data Migration:</b></p>
<p>Ensuring seamless transfer and synchronization of data to achieve consistent and reliable cloud-based information.</p>   
                                </div>

                                <div className="text">
                                    <p><b>Application Migration:</b></p>
<p>Facilitating the migration of applications to cloud environments, ensuring optimal performance and scalability.</p>   
                                </div>

                                <div className="text">
                                    <p><b>Platform Migration and Optimizatio</b></p>
<p>Enhancing application architecture for optimal cloud performance, ensuring scalability and resource optimization.</p>   
                                </div>

                                <p><b>Cloud Migration Tech Stack Expertise</b></p>

<p>Our engineers bring expertise in tools such as AWS Migration Hub, Azure Migrate, and CloudEndure, ensuring a seamless and efficient migration process.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                    <aside className="sidebar services-sidebar">
                        
                        <div className="sidebar-widget services-widget">
                            <div className="widget-inner">
                                <ul>
                                    <li><a href='/Devops'>Devops</a></li>
                                    <li><a href='/cloudintegration'>IT Cloud Integration</a></li>
                                    <li className="active"><a href='/Migration'>Cloud Migration</a></li>
                                    <li><a href='/Operation'>Cloud Operation</a></li>
                                    <li><a href='/SAAS'>SaaS Product Development</a></li>
                                </ul>
                            </div>
                    </div>
                   
                    <div className="sidebar-widget info-widget">
                            <div className="widget-inner">
                        <div className="image"><Link to="contact"><img src={require('../../images/resource/featured-image-4.jpg')} alt="featured-image-4"/></Link></div>
                                <div className="lower">
                                    <div className="subtitle">Got any Questions? <br/> Call us Today!</div>
                                    <div className="icon-box"><span className="flaticon-telephone"></span></div>
                                    <div className="phone"><a href="tel:+91 8903500146">+91 8903500146</a></div>
                                    <div className="email"><a href="mailto:info@kloudping.com">info@kloudping.com</a></div>
                                </div>
                            </div>
                        </div>
                    </aside>
                </div>
                
            </div>
        </div>
          </div>
          <div className="theme-separator"></div>
        </>
        )
    }
}
export default Migration