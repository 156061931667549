import React, { Component } from 'react'

import Background3 from '../../images/background/contactus.jpg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './contact.css';
import {Link} from 'react-router-dom'

import $, { data } from "jquery"
class contact extends React.Component {



    componentDidMount() {


        $(".txtOnly").bind("keypress", function (event) {
            if (event.charCode != 0) {
                var regex = new RegExp("^[a-zA-Z]+$");
                var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
                if (!regex.test(key)) {
                    event.preventDefault();
                    return false;
                }
            }
        });
        $(".numberOnly").bind("keypress", function (event) {
            if (event.charCode != 0) {
                var regex = new RegExp("^[0-9]+$");
                var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
                if (!regex.test(key)) {
                    event.preventDefault();
                    return false;
                }
            }
        });

        /* $('.numberOnly').keypress(function(event)
         {
             if(event.which != 8 && isNaN(String.fromCharCode(event.which))){
                 event.preventDefault(); //stop character from entering input
             }
     
         });
         $( ".txtOnly" ).keypress(function(e) {
             var key = e.keyCode;
             if (key >= 48 && key <= 57) {
                 e.preventDefault();
             }
         });*/
         

        $("button").click(function () {
            debugger;

            var model = {
                name: document.getElementById('name').value,
                phone: document.getElementById('phone').value,
                email: document.getElementById('email').value,
                subject: document.getElementById('subject').value,
                message: document.getElementById('message').value

            };
            $.ajax({
                url: "https://localhost:44351/api/ContactUS/post",
                type: "POST",
                data: JSON.stringify(model),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (data) {
                    debugger;
                    alert("Message Sent Successfully!");
                },
                error: function () { toast.success("Thank You! Email Received!!"); }
            })
            //         toast.dismiss();
            //         toast.success("Thank You! Email Received!!");
            //     //     debugger;
            //     //     alert("Data: " + model + "\nStatus: ");
            //      }
            // })






        });





    }
    render() {

        return (
            <>
                <section className="inner-banner alternate">
                    <div className="image-layer" style={{ background: `url(${Background3})`, backgroundRepeat: "no-repeat", backgroundPosition: "0% 50%", backgroundSize: "100% 200%" }}></div>
                    <div className="auto-container">
                        <div className="inner">
                            <div className="title-box">

                            </div>
                        </div>
                    </div>
                </section>

                <section className="contact-section-two">
                    <div className="auto-container">
                        <div className="upper-row">
                            <div className="row clearfix">
                                <div className="text-column col-lg-6 col-md-12 col-sm-12">
                                    <div className="inner">
                                        <div className="sec-title">
                                            <div className="upper-text">Kloudping - Send us a Message</div>
                                            <h2>Do You Have Any Questions? We’ll Be Happy To Assist!</h2>
                                            <div className="lower-text">We will respond to your inquiry as soon as possible. Right now, we receive many inquiries and it may take a little longer than normal before you get a reply. If you need urgent help, we recommend that you call us during our opening hours..</div>
                                        </div>
                                        <div className="social-links">
                                            <ul className="clearfix">
                                                <li><a href="https://www.linkedin.com/company/kloudping" target='_blank'><span className="fab fa-linkedin"></span></a></li>
                                                <li><a href="#"><span className="fab fa-twitter"></span></a></li>
                                                <li><a href="https://www.youtube.com/@Kloudping" target='_blank'><span className="fab fa-youtube"></span></a></li>
                                                <li><a href="https://wa.me/+918903500146" target='_blank'><span className="fab fa-whatsapp"></span></a></li>
                                                <li><a href="#"><span className="fab fa-facebook-square"></span></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-column col-lg-6 col-md-12 col-sm-12" >
                                    <div className="inner">
                                    <div className="form clearfix">
                                        <div className='form-box'>
                                            
                                            <div className="default-form contact-form">
                                                <div className="row clearfix">
                                                    <h2>Contact Us</h2>
                                                    <p>Fill out the form and we'll be in touch soon!</p>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 form-group txtOnly">
                                                        <input id="name" type="text" name="username" placeholder="Your Name" required="" defaultValue="" />
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                        <input id="email" type="email" name="email" placeholder="Email" />
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 form-group numberOnly">
                                                        <input id="phone" type="text" name="phone" placeholder="Phone" />
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                        <input id="subject" type="text" name="subject" placeholder="Subject" />
                                                    </div>
                                                    <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                                        <textarea id="message" name="message" placeholder="Message" ></textarea>
                                                    </div>
                                                    <div className="form-group col-md-12 col-sm-12">
                                                        <button type="submit" className="theme-btn btn-style-one" ><span className="btn-title" >Make a Request</span></button>
                                                    </div>
                                                </div>
                                            </div>                                         
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lower-row">
                            <div className="row clearfix">
                                <div className="contact-info-block col-lg-12 col-md-12 col-sm-12">
                                    <div className="inner wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                        <div className="content-box">
                                            <div className="title-box" style={{ textAlign: 'center' }}   >
                                                <h4><Link to='contact'>Kloudping IT Solutions</Link></h4>
                                                <div className="sub-text">Tindivanam</div>
                                            </div>
                                            <div className="text-content">
                                                <div className="info" >
                                                    <ul>
                                                        <li>No:28,Near GH,Scudder Nagar,Tindivanam,Tamil Nadu 604001</li>
                                                        <li>Call us <a href="tel: +91 8903500146"><strong> +91 8903500146</strong></a></li>
                                                        <li><a href="mailto:info@kloudping.com"><strong>info@kloudping.com</strong></a></li>
                                                    </ul>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="contact-info-block col-lg-12 col-md-12 col-sm-100">
                                    <iframe className="map-canvas" src="https://maps.google.com/maps?q=No:%2028,%20near%20GH,%20Scudder%20Nagar,%20Tindivanam,%20Tamil%20Nadu%20604001&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                        allowFullScreen="" loading="lazy"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="call-to-action">
                    <div className="map-pattern-layer"></div>

                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="title-column col-xl-7 col-lg-12 col-md-12 col-sm-12">
                                <div className="inner">
                                    <h2>Do You Need Our IT Solutions? <br />Get Advice From Our Professionals.</h2>
                                </div>
                            </div>
                            <div className="links-column col-xl-5 col-lg-12 col-md-12 col-sm-12">
                                <div className="inner">
                                    <div className="links-box">
                                        <a href='/contact' className="theme-btn btn-style-two"><div className="btn-title">Make a Request</div></a>
                                        <a href='/contact' className="theme-btn btn-style-one"><div className="btn-title">Talk With Expert</div></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ToastContainer />
            </>
        )
    }

}
export default contact
