import React, { Component } from 'react'
import Back8 from '../../images/background/banner-bg-1.jpg'
import CountUp from "react-countup";
import Button from 'react-bootstrap/Button';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useState } from 'react';
import WOW from "wowjs";

function scrollToContact() {
  const contactSection = document.getElementById('contact');
  if (contactSection) {
    contactSection.scrollIntoView({ behavior: 'smooth' });
  }
}
const scrollToSection = (event, sectionId) => {
  event.preventDefault();
  const element = document.getElementById(sectionId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

class java extends Component {


  state = {
    selectedSection: 'javaEnterpriseSolutions',
  };

  scrollToSection = (sectionId) => {
    this.setState({ selectedSection: sectionId });
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  componentDidMount() {

  }

  constructor(props) {
    super(props);
    this.state = {
      content: 'Java Enterprise Solutions', // Initial content
    };
  }

  changeContent = (newContent) => {
    this.setState({ content: newContent });
  };


  render() {
    const { selectedSection } = this.state;
    return (
      <>
        <section className="inner-banner alternate">
          <div className="image-layer" style={{ background: `url(${Back8})` }}></div>
          <div className="auto-container">
            <div className="inner">
              <div className="title-box">
                <h1>Hire Full-Stack Java developer</h1>

              </div>
            </div>
          </div>

        </section>
        <section className="fun-facts-section">
          <div className="auto-container">
            <div className="fact-counter">
              <div className="row clearfix">
                <div className="counter-column col-lg-4 col-md-4 col-sm-12">
                  <div className="inner wow fadeInUp" data-wow-delay="0ms">
                    <div className="upper-title">Number of Projects</div>
                    <div className="count-box">
                      <CountUp
                        className="count-text"
                        end={5}
                        enableScrollSpy="true"
                        scrollSpyOnce="true"
                      >
                        0
                      </CountUp>
                    </div>
                    <div className="counter-title">Get facile accessible software development services with Fullstack Developers</div>
                  </div>
                </div>

                <div className="counter-column col-lg-4 col-md-4 col-sm-12 wow fadeInUp">
                  <div className="inner wow fadeInUp" data-wow-delay="300ms">
                    <div className="upper-title">Number of Consultants</div>
                    <div className="count-box">
                      <CountUp
                        className="count-text"
                        end={8}
                        enableScrollSpy="true"
                        scrollSpyOnce="true"
                      >
                        0
                      </CountUp>
                      +
                    </div>
                    <div className="counter-title">Hire Top Java Consultants for project Responsibilities and Duties</div>
                  </div>
                </div>

                <div className="counter-column col-lg-4 col-md-4 col-sm-12 wow fadeInUp">
                  <div className="inner wow fadeInUp" data-wow-delay="600ms">
                    <div className="upper-title">Years of Experience</div>
                    <div className="count-box">
                      <CountUp
                        className="count-text"
                        end={10}
                        enableScrollSpy="true"
                        scrollSpyOnce="true"
                      >
                        0
                      </CountUp>
                    </div>
                    <div className="counter-title">Since 20+ years we have been helping more than 500 companies in digitalization</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="sidebar-page-container services-page">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="content-side col-lg-8 col-md-12 col-sm-12">
                <div className="services-content">
                  <div className="service-details">
                    <div className="content">
                      <div id="javaEnterpriseSolutions" style={{ display: selectedSection === 'javaEnterpriseSolutions' ? 'block' : 'none' }}>
                        <h3>Java Enterprise Solutions</h3>
                        <div className="text">
                          <p>Kloudping provides comprehensive Java enterprise solutions, enabling businesses to build scalable and secure applications that meet the complex demands of enterprise-level environments. Our Java experts leverage cutting-edge technologies and frameworks to deliver robust and reliable enterprise solutions.</p>

                          <ul className="list-style-one">
                            <li>Scalable and secure applications for enterprise</li>
                            <li>Cutting-edge Java technologies and frameworks</li>
                            <li>Robust and reliable solutions</li>
                            <li>Enterprise-level Java solutions</li>
                            <Button variant="primary" size="lg" onClick={() => scrollToContact()}>   Let's Connect for your Project </Button>{' '}
                          </ul>
                        </div>
                      </div>
                      <div id="javaMobileAppDevelopment" style={{ display: selectedSection === 'javaMobileAppDevelopment' ? 'block' : 'none' }}>
                        <h3>JavaMobileAppDevelopment</h3>
                        <div className="text">
                          <p>Kloudping offers Java mobile app development services, building feature-rich and reliable mobile applications using the Java programming language. Our developers leverage Java frameworks and technologies to deliver engaging and high-performing mobile solutions.</p>

                          <ul className="list-style-one">
                            <li>Innovative and user-friendly mobile apps</li>
                            <li>Java frameworks for enhanced functionality</li>
                            <li>High-performing mobile solutions</li>
                            <li>Delivering engaging user experiences</li>
                            <Button variant="primary" size="lg" onClick={() => scrollToContact()}>   Let's Connect for your Project </Button>{' '}
                          </ul>
                        </div>
                      </div>
                      <div id="javaWebDevelopment" style={{ display: selectedSection === 'javaWebDevelopment' ? 'block' : 'none' }}>
                        <h3>JavaWebDevelopment</h3>
                        <div className="text">
                          <p>Kloudping specializes in Java web development services, creating dynamic and robust web applications using the Java programming language. Our experts leverage Java frameworks and technologies to deliver scalable and high-performing web solutions.</p>

                          <ul className="list-style-one">
                            <li>Dynamic web applications using Java</li>
                            <li>Leveraging Java frameworks for efficiency</li>
                            <li>Scalable and high-performing web solutions</li>
                            <li>Expertise in Java web development
                            </li>
                            <Button variant="primary" size="lg" onClick={() => scrollToContact()}>   Let's Connect for your Project </Button>{' '}
                          </ul>
                        </div>
                      </div>
                      <div id="javaApplicationMigration" style={{ display: selectedSection === 'javaApplicationMigration' ? 'block' : 'none' }}>
                        <h3>JavaApplicationMigration</h3>
                        <div className="text">
                          <p>Kloudping offers seamless Java application migration services, ensuring a smooth transition to newer versions or different platforms. Our experts handle code refactoring, database migration, and infrastructure updates for a successful migration.
                          </p>

                          <ul className="list-style-one">
                            <li>Seamless transition to newer versions/platforms</li>
                            <li>Expert handling of code refactoring</li>
                            <li>Smooth database migration process</li>
                            <li>Infrastructure updates for optimal performance</li>
                            <Button variant="primary" size="lg" onClick={() => scrollToContact()}>   Let's Connect for your Project </Button>{' '}
                          </ul>
                        </div>
                      </div>
                      <div id="javaSoftwareDevelopment" style={{ display: selectedSection === 'javaSoftwareDevelopment' ? 'block' : 'none' }}>
                        <h3>JavaSoftwareDevelopment</h3>
                        <div className="text">
                          <p>Kloudping delivers tailored Java software development solutions to address specific business needs. Our experts leverage advanced Java technologies for scalable, high-performance, and secure application development.</p>

                          <ul className="list-style-one">
                            <li>Custom solutions for distinct requirements</li>
                            <li>Harnessing cutting-edge Java technologies</li>
                            <li>Building scalable and high-performing applications</li>
                            <li>Ensuring robust security for Java applications
                            </li>
                            <Button variant="primary" size="lg" onClick={() => scrollToContact()}>   Let's Connect for your Project </Button>{' '}
                          </ul>
                        </div>
                      </div>
                      <h3>Outsource Your Java Development</h3>
                      <div className="text">
                        <p>Our skilled Java developers specialize in rapidly scaling your project and creating robust user interfaces for web and mobile applications..</p>

                      </div>

                      <div className="two-col">
                        <div className="row clearfix">
                          <div className="column col-lg-6 col-md-6 col-sm-12">
                            <div className="image"><img src={require('../../images/resource/featured-image-4.jpg')} alt="featured-image-4" /></div>
                          </div>
                          <div className="column col-lg-6 col-md-6 col-sm-12">
                            <div className="image"><img src={require('../../images/resource/featured-image-5.jpg')} alt="featured-image-5" /></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                <aside className="sidebar services-sidebar">

                  <div className="sidebar-widget services-widget">
                    <div className="widget-inner">
                      <ul>
                        <li><a onClick={() => this.scrollToSection('javaEnterpriseSolutions')}>Java Enterprise Solutions</a></li>
                        <li><a onClick={() => this.scrollToSection('javaMobileAppDevelopment')}>
                          Java Mobile App Development
                        </a></li>
                        <li><a onClick={() => this.scrollToSection('javaWebDevelopment')}>
                          Java Web Development
                        </a></li>
                        <li>
                          <a onClick={() => this.scrollToSection('javaApplicationMigration')}>
                            Java Application Migration
                          </a></li>
                        <li>
                          <a onClick={() => this.scrollToSection('javaSoftwareDevelopment')}>
                            Java Software Development
                          </a></li>
                      </ul>
                    </div>
                  </div>

                  <div className="sidebar-widget info-widget">
                    <div className="widget-inner">
                      <div className="image"><a href="/contact"><img src={require('../../images/resource/featured-image-4.jpg')} alt="featured-image-4" /></a></div>
                      <div className="lower">
                        <div className="subtitle">Got any Questions? <br />Call us Today!</div>
                        <div className="icon-box"><span className="flaticon-telephone"></span></div>
                        <div className="phone"><a href="tel:+91 89035 00146">+91 89035 00146</a></div>
                        <div className="email"><a href="mailto:info@kloudping.com">info@kloudping.com</a></div>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>

            </div >
          </div >
        </div >
        <div className="theme-separator"></div>
        <section className="cases-section">
          <div className="gradient-layer"></div>

          <div className="auto-container">
            <div className="sec-title centered">
              <div className="upper-text">Kloudping Advanced Java Technology Stacks</div>

              <div className="lower-text">
                We leverage a comprehensive array of cutting-edge Java technologies to craft customized software solutions, elevating user experiences and driving competitive advantage.
              </div>
            </div>

            <div className="carousel-box">
              <OwlCarousel
                className="cases-carousel theme-carousel owl-theme owl-carousel"
                loop="true"
                margin={30}
                autoheight="true"
                lazyload="true"
                nav="true"
                dots={false}
                autoplay="true"
                autoplayTimeout={7000}
                smartSpeed={700}
                items={3}
              >
                <div className="slide-item">
                  <div className="case-block">
                    <div className="inner-box">
                      <figure className="image-box">
                        <a href="/casestudies">
                          <img
                            src={require("../../images/clients/java.png")}
                            alt="case-image-1"
                            title=""
                            style={{ backgroundBlendMode: "multiply", backgroundColor: "white" }}
                          />
                        </a>
                      </figure>

                    </div>
                  </div>
                </div>

                <div className="slide-item">
                  <div className="case-block">
                    <div className="inner-box">
                      <figure className="image-box">
                        <a href="/casestudies">
                          <img
                            src={require("../../images/clients/spring.png")}
                            alt="case-image-2"
                            title=""
                            style={{ backgroundBlendMode: "multiply", backgroundColor: "white" }}
                          />
                        </a>
                      </figure>

                    </div>
                  </div>
                </div>

                <div className="slide-item">
                  <div className="case-block">
                    <div className="inner-box">
                      <figure className="image-box">
                        <a href="/casestudies">
                          <img
                            src={require("../../images/clients/helidon.png")}
                            alt="case-image-1"
                            title=""
                            style={{ backgroundBlendMode: "multiply", backgroundColor: "white" }}
                          />
                        </a>
                      </figure>

                    </div>
                  </div>
                </div>

                <div className="slide-item">

                  <div className="case-block">
                    <div className="inner-box">
                      <figure className="image-box">
                        <a href="/casestudies">
                          <img
                            src={require("../../images/clients/kafka.png")}
                            alt="case-image-2"
                            title=""
                            className='filter'

                          />
                        </a>
                      </figure>

                    </div>
                  </div>
                </div>


              </OwlCarousel>
            </div>


          </div>

        </section>

        <div className="theme-separator"></div>
        <section className="contact-section-two" id="contact">
          <div className="auto-container">

            <div className="upper-row">
              <div className="row clearfix">

                <div className="text-column col-lg-6 col-md-12 col-sm-12">
                  <div className="inner">
                    <div className="sec-title">
                      <div className="upper-text">Kloudping - Send us a Message</div>
                      <h2>Do You Have Any Questions? We’ll Be Happy To Assist!</h2>
                      <div className="lower-text">We will respond to your inquiry as soon as possible. Right now, we receive many inquiries and it may take a little longer than normal before you get a reply. If you need urgent help, we recommend that you call us during our opening hours..</div>
                    </div>

                    <div className="social-links">
                      <ul className="clearfix">
                        <li><a href="https://www.linkedin.com/company/kloudping" target='_blank'><span className="fab fa-linkedin"></span></a></li>
                        <li><a href=""><span className="fab fa-twitter"></span></a></li>
                        <li><a href="https://www.youtube.com/@Kloudping" target='_blank'><span className="fab fa-youtube"></span></a></li>
                        <li><a href="https://wa.me/+918903500146" target='_blank'><span className="fab fa-whatsapp"></span></a></li>
                        <li><a href=""><span className="fab fa-facebook-square"></span></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="form-column col-lg-6 col-md-12 col-sm-12">
                  <div className="inner">
                    <div className='form-box'>
                      <div className="default-form contact-form">
                        <div className="row clearfix">
                          <h2>Contact Us</h2>
                          <p>Fill out the form and we'll be in touch soon!</p>
                          <div className="col-lg-6 col-md-6 col-sm-12 form-group txtOnly">
                            <input id="name" type="text" name="username" placeholder="Your Name" required="" defaultValue="" />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <input id="email" type="email" name="email" placeholder="Email" />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 form-group numberOnly">
                            <input id="phone" type="text" name="phone" placeholder="Phone" />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <input id="subject" type="text" name="subject" placeholder="Subject" />
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                            <textarea id="message" name="message" placeholder="Message" ></textarea>
                          </div>
                          <div className="form-group col-md-12 col-sm-12">
                            <button type="submit" className="theme-btn btn-style-one"><span className="btn-title" >Make a Request</span></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>


          </div>
        </section>
        <div className="theme-separator"></div>
      </>
    )
  }
}
export default java
