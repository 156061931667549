import React from 'react'
import Button from 'react-bootstrap/Button';
export default function StartBtn(props) {

    const initialAction = () => {
        props.actions.initialAction();
    }

    return (
        <div>
            <Button variant="success"className='start-btn' onClick={() => initialAction()}>Let's Get Started</Button>
        </div >
    )
    }