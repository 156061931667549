import React, { Component } from 'react'
import Back3 from "../../images/background/banner-bg-2.jpg"
import { Accordion } from 'react-bootstrap'
import {Link} from 'react-router-dom'


const FAQ = () =>{
 
  
    return (
      <>
         <section className="inner-banner alternate">
        <div className="image-layer"  style={{ background: `url(${Back3})`}}></div>
		<div className="auto-container">
            <div className="inner">
    			<div className="title-box">
                    <h1>Read FAQ’s</h1>
                    <div className="d-text">Frequently Asked Questions by our Clients</div>
                </div>
            </div>
		</div>
    </section>
       
        <section className="faqs-section">
        <div className="auto-container">
            <div className="row clearfix">
              
                <div className="column col-lg-6 col-md-12 col-sm-12">
                    <div className="inner">
                        <div className="accordion-box" >
                        
                            <Accordion className="accordion block">
                                <Accordion.Item eventKey="0"   >
                                <Accordion.Header   > <strong >What type of services Kloudping offers? </strong> <div className="icon flaticon-down-arrow"   style={{Color: "white"}}></div></Accordion.Header>
                              
                                    <Accordion.Body className="content">
                                    Our primary area of expertise lies in Cloud Computing, which includes cloud infrastructure, migration, integration, and security solutions. With a dedicated team of tech experts, we enable businesses to scale, optimize, and innovate securely. In addition to cloud services, we excel in data infrastructure management, ensuring that your data serves as the cornerstone of informed decisions and supports your growth. Our IT project outsourcing services encompass custom software development, product engineering, and AI/machine learning expertise, ensuring the seamless and efficient delivery of your projects. We also provide IT infrastructure optimization services to enhance operational efficiency. 
                                    </Accordion.Body>
                                    </Accordion.Item >
                                    <Accordion.Item eventKey="1" >
                                    <Accordion.Header  ><strong > Why your working process is very simple?</strong><div className="icon flaticon-down-arrow"></div></Accordion.Header>
                              
                              <Accordion.Body className="content">
                              We believe in streamlining processes to make it easier for our clients to navigate and utilize our cloud services seamlessly. A simple working process also reduces the likelihood of errors and ensures a more robust and reliable system overall.
                              </Accordion.Body>
                              </Accordion.Item >

                              <Accordion.Item eventKey="2" >
                                    <Accordion.Header  ><strong > How do you manage IT Services?</strong><div className="icon flaticon-down-arrow"></div></Accordion.Header>
                              
                              <Accordion.Body className="content">
                              Think of us as your IT wizards behind the scenes. We set up your tech, make sure it's all playing nice together, and troubleshoot any issues so you can focus on what you do best. It's like having a personal IT pit crew-always ready to jump in, keep things smooth, and ensure your digital world runs like a well-oiled machine.
                              </Accordion.Body>
                              </Accordion.Item >

                              <Accordion.Item eventKey="3" >
                                    <Accordion.Header  ><strong > How to book an appointment with experts? </strong><div className="icon flaticon-down-arrow"></div></Accordion.Header>
                              
                              <Accordion.Body className="content">
                              You can simply click on the <a href="/contact"><strong>Contact Us</strong> </a>button and update your requirements. On the other hand, you can just mail us at <a href="mailto:&#105;&#110;&#102;&#111;&#064;&#107;&#108;&#111;&#117;&#100;&#112;&#105;&#110;&#103;&#046;&#099;&#111;&#109;">
                        <strong>info@kloudping.com</strong>
                        </a> or call the <a href="tel:+91 89035 00146">
                          <span className=""></span>
                          <strong>+91 89035 00146</strong>
                        </a> number.
                              </Accordion.Body>
                              </Accordion.Item >
                              
                           </Accordion> 
                     </div>
                    </div>
              </div>
              <div className="column col-lg-6 col-md-12 col-sm-12">
                    <div className="inner">
                        <div className="accordion-box">
                        <Accordion className="accordion block">
                                <Accordion.Item eventKey="0"   >
                                <Accordion.Header   > <strong >How Kloudping IT Solutions can benefit our business? </strong> <div className="icon flaticon-down-arrow" ></div></Accordion.Header>
                              
                                    <Accordion.Body className="content">
                                    We specialize in optimizing digital landscapes to enhance operational efficiency and drive business growth. Our tailored IT solutions not only streamline your current processes but also pave the way for innovative advancements, ensuring your business stays ahead in the ever-evolving tech landscape. From seamless integrations to robust cloud security measures, we've got your IT needs covered, empowering you to focus on what you do best – steering your business towards success.
                                    </Accordion.Body>
                                    </Accordion.Item >
                                    <Accordion.Item eventKey="1" >
                                    <Accordion.Header  ><strong >How many clients do you have?</strong><div className="icon flaticon-down-arrow"></div></Accordion.Header>
                              
                              <Accordion.Body className="content">
                              We have had the privilege of collaborating on over 15 diverse projects with clients from around the globe till now.
                              </Accordion.Body>
                              </Accordion.Item >

                              <Accordion.Item eventKey="2" >
                                    <Accordion.Header  ><strong >How can I subscribe to latest IT news?</strong><div className="icon flaticon-down-arrow"></div></Accordion.Header>
                              
                              <Accordion.Body className="content">
                              You can subscribe to popular IT news websites or follow their social media accounts for real-time updates. And hey, if you don't want to go too far, you're already in the right place-We share the latest IT news on our social media too. Find them <a href="/contact"><strong>here</strong> </a> and  feel free follow them for a curated dose of tech goodness!
                              </Accordion.Body>
                              </Accordion.Item >

                             
                              

                              <Accordion.Item eventKey="4" >
                                    <Accordion.Header  ><strong >How to benefit from Data Machine Learning?</strong><div className="icon flaticon-down-arrow"></div></Accordion.Header>
                              
                              <Accordion.Body className="content">
                              Data and machine learning are like the dynamic duo of the digital age! By harnessing the power of data, businesses can uncover valuable insights, make informed decisions, and even predict future trends. It's not just about collecting information; it's about turning that raw data into actionable knowledge. From optimizing operations and enhancing customer experiences to gaining a competitive edge, the possibilities are endless. It's not just a technological evolution; it's a business revolution!
                              </Accordion.Body>
                              </Accordion.Item >
                              
                           </Accordion> 
                    

                        </div>
                    </div>
                </div>

            </div>

           

        </div>
    </section>

    <section className="call-to-action">
        <div className="map-pattern-layer"></div>

        <div className="auto-container">
            <div className="row clearfix">
                <div className="title-column col-xl-7 col-lg-12 col-md-12 col-sm-12">
                    <div className="inner">
                        <h2>Do You Need Our IT Solutions? <br/>Get Advice From Our Professionals.</h2>
                    </div>
                </div>
                <div className="links-column col-xl-5 col-lg-12 col-md-12 col-sm-12">
                    <div className="inner">
                        <div className="links-box">
                            <Link to='contact' className="theme-btn btn-style-one"><div className="btn-title">Make a Request</div></Link>
                            <Link to='contact' className="theme-btn btn-style-one"><div className="btn-title">Talk With Expert</div></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
            </>
    )  
}

 export default FAQ