
import React, { Component } from 'react'
import back1 from "../../images/background/aboutus.jpg"
import CountUp from 'react-countup';
import { Link } from 'react-router-dom'

class about extends Component {
    componentDidMount() {
    }
    render() {
        return (
            <>
                <section className="inner-banner alternate">
                    <div className="image-layer" style={{ background: `url(${back1})`, backgroundRepeat: "no-repeat", backgroundPosition: "0% 5%", backgroundSize: "100% 200%" }}  ></div>
                    <div className="auto-container">
                        <div className="inner">
                            <div className=""></div>
                            <div className="title-box">
                                <h1>About Us</h1>
                                <div className="d-text">Commitment to Excellence Is Our Guiding Light</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="about-section-three">
                    <div className="auto-container">
                        <div className="sec-title centered">
                            <div className="upper-text">Kloudping - Welcome to IT Solutions</div>

                        </div>

                        <div className="upper-row">
                            <div className="row clearfix">

                                <div className="text-column col-lg-12 col-md-12 col-sm-12">
                                    <div className="inner">
                                        <div className="text">
                                            <p>Our journey has its roots deeply embedded in our unyielding pursuit of excellence.
                                                As a leading software development company, we remain steadfast in our commitment to delivering high-quality IT services that empower both local IT companies and startups. We comprehend the uniqueness of each business and their individual IT requirements.
                                                Our approach, though simple, carries a profound impact: we truly grasp the genuine needs and expectations of our customers.
                                                Guided by a skilled and experienced management team, tailored solutions are meticulously crafted for the ever-evolving realm of IT.
                                                From the initial spark of an idea to the final execution, we firmly believe in processes that are geared towards achieving excellence and fostering a culture of continuous improvement.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="text-column col-lg-6 col-md-12 col-sm-12">
                                    <div className="inner">
                                        <div className="text">
                                            <ul className="list-style-one">
                                                <li>We are committed to providing quality IT Services</li>
                                                <li>Our benefits are endless for local IT Companies & Startups</li>
                                                <li>Really know the true needs and expectations of customers</li>
                                                <li>Talented & experienced management solutions for IT</li>
                                                <li>Processes of achieving the excellence and continue improvements</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>

                        <div className="lower-row">
                            <div className="row clearfix">

                                <div className="featured-block col-lg-4 col-md-6 col-sm-12">
                                    <div className="inner-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                        <div className="image-box">
                                            <Link to="services"><img src={require('../../images/resource/featured-image-4.jpg')} alt="featured-image-4" title="" /></Link>
                                        </div>
                                        <div className="lower-box">
                                            <h3><a href='/services'>Easier To Implement</a></h3>
                                            <div className="text">While it can seem to be a huge task ahead, you can break up your onboarding into smaller, more manageable steps. This can make a huge difference to your implementation as it has a lot of benefits.</div>
                                            <div className="more-link"><a href='/services'><span className="fa fa-arrow-right"></span></a></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="featured-block col-lg-4 col-md-6 col-sm-12">
                                    <div className="inner-box wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                                        <div className="image-box">
                                            <Link to="services"><img src={require('../../images/resource/featured-image-5.jpg')} alt="featured-image-5" title="" /></Link>
                                        </div>
                                        <div className="lower-box">
                                            <h3><a href='/services'>Increase Growth Rates</a></h3>
                                            <div className="text">Growth rates are utilized by analysts, investors, and a company’s management to assess a firm’s growth periodically and make predictions about future performance.</div>
                                            <div className="more-link"><a href='/services'><span className="fa fa-arrow-right"></span></a></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="featured-block col-lg-4 col-md-6 col-sm-12">
                                    <div className="inner-box wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                                        <div className="image-box">
                                            <Link to="services"><img src={require('../../images/resource/featured-image-6.jpg')} alt="featured-image-6" title="" /></Link>
                                        </div>
                                        <div className="lower-box">
                                            <h3><a href='/services'>Branding Of Future</a></h3>
                                            <div className="text">The future of our company branding through social media will likely continue to evolve and change as technology and social media platforms develop.</div>
                                            <div className="more-link"><a href='/services'><span className="fa fa-arrow-right"></span></a></div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                <div className="theme-separator"></div>
                <section className="locations-section">
                    <div className="auto-container">
                        <div className="sec-title centered">
                            <div className="upper-text">Kloudping IT Services</div>
                            <h2>Kloudping IT Solutions is a new and innovative company</h2>
                            <p>We have served 15+ customers from different regions and industries</p>
                            <div className="lower-text">We value customer experience as a universal standard and we adapt to the local needs and preferences of each market.</div>
                        </div>
                        <div className="map-box">

                            <div className="map-image">
                                <img src={require('../../images/icons/map-pattern.png')} alt="map-pattern" title="" />
                            </div>


                            <div className="location-point loc-one">
                                <div className="inner">4+ Projects in  TamilNadu , India</div>
                            </div>

                            <div className="location-point loc-two">
                                <div className="inner"> 4+ Projects in Maharashtra , India</div>
                            </div>

                            <div className="location-point loc-three">
                                <div className="inner"> 2+ Projects in USA</div>
                            </div>

                            <div className="location-point loc-four">
                                <div className="inner"> 5+ Projects in Odisha , India</div>
                            </div>



                        </div>
                    </div>
                </section>
                <section className="fun-facts-section">

                    <div className="auto-container">
                        <div className="fact-counter">

                            <div className="row clearfix">

                                <div className="counter-column col-lg-4 col-md-4 col-sm-12">
                                    <div className="inner wow fadeInUp" data-wow-delay="0ms">
                                        <div className="upper-title">Projects Worked On</div>
                                        <div className="count-box"><CountUp className="count-text" end={15} enableScrollSpy="true" scrollSpyOnce="true">0</CountUp></div>
                                        <div className="counter-title">for customers</div>
                                    </div>
                                </div>


                                <div className="counter-column col-lg-4 col-md-4 col-sm-12 wow fadeInUp">
                                    <div className="inner wow fadeInUp" data-wow-delay="300ms">
                                        <div className="upper-title">Powerful Team to Focus</div>
                                        <div className="count-box"><CountUp className="count-text" end={12} enableScrollSpy="true" scrollSpyOnce="true">0</CountUp>+</div>
                                        <div className="counter-title">Expert Members</div>
                                    </div>
                                </div>


                                <div className="counter-column col-lg-4 col-md-4 col-sm-12 wow fadeInUp">
                                    <div className="inner wow fadeInUp" data-wow-delay="600ms">
                                        <div className="upper-title">Launched Case Studies</div>
                                        <div className="count-box"><CountUp className="count-text" end={3} enableScrollSpy="true" scrollSpyOnce="true">0</CountUp></div>
                                        <div className="counter-title">Fully Launched</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <br />
                </section>
                <section> <div className="theme-separator"></div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </section>



                <section className="team-section">
                    <div className="pattern-layer"></div>

                    < div className="auto-container">
                        <div className="row clearfix">

                            <div className="column col-lg-6 col-md-12 col-sm-12">
                                <div className="inner">
                                    <div className="sec-title">
                                        <div className="upper-text">Ambitious & Dedicated Team</div>
                                        <h2>Kloudping <strong>IT Experts</strong></h2>
                                    </div>
                                    <div className="text-content">
                                        <div className="text"> Our Kloudping team that provides highest quality ICT solution, support and services. We are committed in providing excellent client services. We have the ability to draw from an experienced base of personnel whilst maintaining a personalized form of business.</div>
                                    </div>

                                    <div className="team-block wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                        <div className="inner-box">
                                            <div className="image-box">
                                                <Link to='about'><img src={require('../../images/resource/team-image-1.jpeg')} alt="team-image-1"  style={{width:"78%"}}/></Link>
                                            </div>
                                            <div className="title-box">
                                                <h3 className="name">Rajesh kumar</h3>
                                                <div className="designation">Senior Full Stack Developer</div>
                                            </div>
                                            <div className="text">"As a Senior Full Stack Developer, we are the architects of our digital universe. With a wealth of experience and expertise, we craft the front-end and back-end of web applications, seamlessly merging creativity and functionality. Our code is the backbone of innovation, driving the user experience and shaping the digital future."</div>
                                        </div>
                                    </div>
                                    <div className="team-block wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                        <div className="inner-box">
                                            <div className="image-box">
                                                <Link to='about'><img src={require('../../images/resource/team-image-2.jpg')} alt="team-image-1" /></Link>
                                            </div>
                                            <div className="title-box">
                                                <h3 className="name">Gowthaman Sankar</h3>
                                                <div className="designation">Cloud Engineer</div>
                                            </div>
                                            <div className="text">"As a Cloud Engineer, we are the architects of digital transformation. Our expertise lies in designing, deploying, and optimizing cloud infrastructure, ensuring scalability, security, and efficiency in the digital realm. We harness the power of the cloud to drive innovation and empower businesses to reach new heights."</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="column col-lg-6 col-md-12 col-sm-12">
                                <div className="inner">

                                    <div className="team-block wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                        <div className="inner-box">
                                            <div className="image-box">
                                                <Link to='about'><img src={require('../../images/resource/team-image-2.jpg')} alt="team-image-2" /></Link>
                                            </div>
                                            <div className="title-box">
                                                <h3 className="name">Teena</h3>
                                                <div className="designation">CEO , HR Manager</div>
                                            </div>
                                            <div className="text">"Our visionary CEO sets the course for our IT company, driving innovation, growth, and strategic direction. With a relentless commitment to excellence, they lead our team to new heights in the dynamic world of Information Technology."
                                            </div>
                                        </div>
                                    </div>

                                    <div className="team-block wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                                        <div className="inner-box">
                                            <div className="image-box">
                                                <Link to='about'><img src={require('../../images/resource/team-image-31.jpg')} alt="team-image-31" /></Link>
                                            </div>
                                            <div className="title-box">
                                                <h3 className="name">Gopal Krishna</h3>
                                                <div className="designation">Head of Sales, Stratagy & Marketing in IT</div>
                                            </div>
                                            <div className="text"><Link to=""></Link> "Leading the way in IT excellence, our Head of Sales, Strategy & Marketing combines visionary leadership with strategic prowess. They steer our sales force, drive market strategies, and harness cutting-edge technology to position our IT solutions for success. With a focus on innovation, this role ensures our growth and market leadership in the dynamic world of Information Technology." </div>
                                        </div>
                                    </div>
                                    <div className="team-block wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                                        <div className="inner-box">
                                            <div className="image-box">
                                                <Link to='about'><img src={require('../../images/resource/team-image-3.jpeg')} alt="team-image-3" /></Link>
                                            </div>
                                            <div className="title-box">
                                                <h3 className="name"><Link to='about'>Chandru Nandha
                                                </Link></h3>
                                                <div className="designation">Senior DevOps Enginner</div>
                                            </div>
                                            <div className="text">"As a Senior DevOps Engineer, we are the driving force behind seamless software delivery and infrastructure automation. With years of expertise, we optimize development pipelines, enhance system reliability, and accelerate innovation. Our commitment to DevOps principles paves the way for agile, efficient, and resilient IT operations."</div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>


                    </div>
                </section>
            </>
        )
    }
}
export default about
