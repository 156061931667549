import React,{ Component } from "react";
import Background from "../../images/background/image-1.jpg";
import Background2 from "../../images/background/image-2.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import WOW from "wowjs";
import CountUp from "react-countup";
import $ from "jquery";

import 'react-chatbot-kit/build/main.css'

import {Link} from 'react-router-dom'
class home extends Component {







componentDidMount() {



  $(".txtOnly").bind("keypress", function (event) {
    if (event.charCode != 0) {
      var regex = new RegExp("^[a-zA-Z]+$");
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key)) {
        event.preventDefault();
        return false;
      }
    }
  });
  $(".numberOnly").bind("keypress", function (event) {
    if (event.charCode != 0) {
      var regex = new RegExp("^[0-9]+$");
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key)) {
        event.preventDefault();
        return false;
      }
    }
  });
  new WOW.WOW({
    live: false,
  }).init();

  setTimeout(() => {
    $("body").addClass("page-loaded");
    setTimeout(() => {
      $(".preloader").delay(500).fadeOut(0);
    }, 1000);
  }, 500);
}
render() {

  return (
    <>
      <div className="preloader">
        
        <div className="icon"></div>
      </div>

      <section className="banner-section banner-one">
        <div className="banner-curve"></div>
       
  
        <OwlCarousel
          className="banner-carousel  theme-owl-theme  owl-carousel"
          loop="true"
          margin={0}
          autoheight="true"
          lazyload="true"
          nav="true"
          dots="true"
          autoplay="true"
          autoplayTimeout={6000}
          smartSpeed={300}
          items={1}
        >
          <div className="slide-item">
            <div className="auto-container">
              <div className="content-box">
                <div className="round-layer"></div>

                <div className="content">
                  <div className="inner">
                    <div className="sub-title">
                    All Cloud Solutions for Easy Integration
                    </div>
                    {/* <h2>
                      Deliver <strong>Innovative IT Solutions</strong> For
                      Technology Insights
                    </h2> */}
                    <div className="text">
                    Sustainable Software Development & Expert DevOps Services.
                    </div>
                    <div className="links-box">
                      <Link to="about" className="theme-btn btn-style-two" target="_blank">
                        <div className="btn-title">More Details</div>
                      </Link>
                     <a href="https://youtu.be/6eCAGcYdKiw?si=QFrz010lMey3BKj8"
                        className="theme-btn video-btn lightbox-image"
                        target="_blank"
                      >
                        <div className="btn-title">
                          <span className="icon flaticon-play-button"></span>{" "}
                          Watch The Demo
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="content-image">
                  <img
                    src={require("../../images/main-slider/content-image-1.webp")}
                    alt="content-image-1"
                    title=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="slide-item">
            <div className="auto-container">
              <div className="content-box">
                <div className="round-layer"></div>

                <div className="content">
                  <div className="inner alternate">
                    <div className="sub-title">
                    All Cloud Solutions for Easy Integration
                    </div>
                    {/* <h2>
                      <strong>IT Services</strong> To Develop Solutions Your
                      Way!
                    </h2> */}
                    <div className="text">
                    Sustainable Software Development & Expert DevOps Services.
                    </div>
                    <div className="links-box">
                      <Link to="about" className="theme-btn btn-style-one">
                        <div className="btn-title">More Details</div>
                      </Link>
                     <Link to="/services"
                        className="theme-btn btn-style-one"
                      >
                        <div className="btn-title">Our Services</div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="content-image">
                  <img
                    src={require("../../images/main-slider/content-image-2.webp")}
                    alt="content-image-2"
                    title=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="slide-item">
            <div className="auto-container">
              <div className="content-box">
                <div className="round-layer"></div>

                <div className="content">
                  <div className="inner">
                    <div className="sub-title">
                    All Cloud Solutions for Easy Integration
                    </div>
                    {/* <h2>
                      Deliver <strong>Innovative IT Solutions</strong> For
                      Technology Insights
                    </h2> */}
                    <div className="text">
                    Sustainable Software Development & Expert DevOps Services.
                    </div>
                    <div className="links-box">
                      <Link to="about" className="theme-btn btn-style-one">
                        <div className="btn-title">More Details</div>
                      </Link>
                     <a href="https://youtu.be/6eCAGcYdKiw?si=QFrz010lMey3BKj8"
                        className="theme-btn video-btn lightbox-image"
                      >
                        <div className="btn-title">
                          <span className="icon flaticon-play-button"></span>{" "}
                          Watch The Demo
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="content-image">
                  <img
                    src={require("../../images/main-slider/content-image-1.webp")}
                    alt="content-image-1"
                    title=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="slide-item">
            <div className="auto-container">
              <div className="content-box">
                <div className="round-layer"></div>

                <div className="content">
                  <div className="inner alternate">
                    <div className="sub-title">
                    All Cloud Solutions for Easy Integration
                    </div>
                    {/* <h2>
                      <strong>IT Services</strong> To Develop Solutions Your
                      Way!
                    </h2> */}
                    <div className="text">
                    Sustainable Software Development & Expert DevOps Services.
                    </div>
                    <div className="links-box">
                      <Link to="about" className="theme-btn btn-style-one">
                        <div className="btn-title">More Details</div>
                      </Link>
                     <Link to="/services"
                        className="theme-btn btn-style-one"
                      >
                        <div className="btn-title">Our Services</div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="content-image">
                  <img
                    src={require("../../images/main-slider/content-image-2.webp")}
                    alt="content-image-2"
                    title=""
                  />
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>
     
      </section>

      <section className="about-section">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="text-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner">
                <div className="sec-title">
                  <div className="upper-text">
                    Welcome to Kloudping IT Solution
                  </div>
                  <h2>
                    <strong>Your Preferred IT Partner</strong>
                  </h2>
                </div>

                <div className="text-content">
                  {/* <p>

                  Welcome to our world of cloud excellence! With a proven track record of successfully completing numerous cloud projects, 
                  we take pride in our expert team dedicated to delivering top-notch solutions. Harness the experience of a team that has mastered the intricacies of cloud technology. 
                  Our journey is defined by successful projects and satisfied clients. Trust us to bring that expertise to your doorstep.

                  </p> */}
                  <p>
                  At Kloudping, we specialize in delivering comprehensive web and app development services, backed by our expertise in DevOps and cloud solutions. 
                  As a trusted IT partner, we've successfully completed a wide range of projects, from eCommerce app development to customized CRMs, product development, and various 
                  types of website development. We excel in DevOps, legacy deployment, infrastructure management, CI/CD delivery, cloud migration, integration and operations.
                  <br />
                  Dedicated to startups, small businesses, and large enterprises, we provide innovative, reliable IT solutions to drive growth and efficiency.
                  <br />
                  Count on us as an innovative, reliable, and effective IT partener that empowers your business to thrive in the digital age.
                  </p>
                  {/* <ul className="list-style-one">
                    <li>We are committed to providing quality IT Services</li>
                    <li>
                      Our benefits are endless for local IT Companies &
                      Startups
                    </li>
                    <li>
                      Really know the true needs and expectations of customers
                    </li>
                    <li>
                      Talented & experienced management solutions for IT
                    </li>
                    <li>
                      Processes of achieving the excellence and continue
                      improvements
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>

            <div className="image-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner">
                <div className="images">
                  <div className="row clearfix">
                    <figure
                      className="image col-md-6 col-sm-6 wow lefttop"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <img 
                        src={require("../../images/resource/sliderimage1.png")}
                        alt="about-image-1"
                        title=""
                       
                      />
                    </figure>
                    <figure
                      className="image col-md-6 col-sm-6 wow fadeInRight"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <img
                        src={require("../../images/resource/sliderimage2.png")}
                        alt="about-image-2"
                        title=""
                      />
                    </figure>
                    <figure
                      className="image col-md-6 col-sm-6 wow fadeInLeft"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                     
                    >
                      <img
                        src={require("../../images/resource/sliderimage3.png")}
                        alt="about-image-3"
                        title=""
                        
                      /> 
                    </figure>
                    <figure
                      className="image col-md-6 col-sm-6 wow fadeInRight"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
               
                    >
                      <img
                        src={require("../../images/resource/sliderimage4.png")}
                        alt="about-image-4"
                        title=""
              
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="theme-separator"></div>

      <section className="services-section">
        <div className="gradient-layer"></div>
        <div className="pattern-layer"></div>

        <div className="auto-container">
          <div className="row clearfix">
            <div className="col col-lg-4 col-md-12 col-sm-12">
              <div
                className="sec-title wow fadeInUp"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div className="upper-text">Kloudping IT Services</div>
                <h2>
                  <strong>Solutions And Focus Areas</strong>
                </h2>
                <div className="lower-text">
                Digital Transformation Through Cloud Implementation.
                </div>
              </div>
            {/* </div> */}
            {/* <div className= "service-block wow fadeInUp col-lg-4 col-md-6 col-sm-12"> */}
              <div
                className="service-block wow fadeInUp"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box">
                  <div className="icon-outer">
                    <span className="icon-bg"></span>
                    <div className="icon-box">
                      <img
                        src={require("../../images/icons/services/1.png")}
                        alt="services-1"
                        title=""
                      />
                    </div>
                  </div>
                  <h3>
                    <Link to="Devops">DevOps</Link>
                  </h3>
                  <div className="text">
                  At Kloudping IT Solutions, we committed to fostering collaboration and communication between your development and operations teams.
                   Our mission is to automate manual processes, enhancing efficiency, reliability, and speed in software delivery. 
                   From Continuous Integration (CI) to Continuous Delivery/Deployment (CD), along with comprehensive Automation, Collaboration, Communication, and Testing, 
                   we ensure seamless DevOps services. Experience a transformative journey as we leverage our expertise to streamline processes and create a robust, efficient cloud environment.
                   Welcome to a future where Kloudping IT Solutions makes your cloud aspirations a reality.
                  </div>
                  <div className="more-link">
                    <Link to="Devops">
                      <span className="fa fa-arrow-right"></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            

            {/* <div className="column col-lg-4 col-md-12 col-sm-12"> */}
              {/* <div
                className="service-block wow fadeInUp"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box">
                  <div className="icon-outer">
                    <span className="icon-bg"></span>
                    <div className="icon-box">
                      <img
                        src={require("../../images/icons/services/2.png")}
                        alt="services-2"
                        title=""
                      />
                    </div>
                  </div>
                  <h3>
                    <Link to="cloudintegration">IT Cloud Migration</Link>
                  </h3>
                  <div className="text">
                  Explore comprehensive cloud migration services encompassing infrastructure migration, data migration, application migration, and platform migration, 
                  all tailored for seamless optimization. Our expertise extends to executing successful cloud migrations, integrating careful planning, security considerations, 
                  and compliance requirements. With a commitment to ongoing management, we guarantee an optimized cloud environment that precisely meets the dynamic needs of your business. 
                  Trust us for a holistic approach to cloud migration, ensuring a smooth transition and enhanced operational efficiency.
                  </div>
                  <div className="more-link">
                    <Link to="cloudintegration">
                      <span className="fa fa-arrow-right"></span>
                    </Link>
                  </div>
                </div>
              </div> */}

              {/* <div
                className="service-block wow fadeInUp"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box">
                  <div className="icon-outer">
                    <span className="icon-bg"></span>
                    <div className="icon-box">
                      <img
                        src={require("../../images/icons/services/3.png")}
                        alt="services-3"
                        title=""
                      />
                    </div>
                  </div>
                  <h3>
                    <Link to="Migration">IT Cloud Intrgration</Link>
                  </h3>
                  <div className="text">
                  Kloudping IT Solutions specializes in pragmatic cloud integration, connecting diverse software applications into a unified system for tailored functionality. 
                  Our expertise extends to streamlining workflows and business processes across various cloud environments, promoting operational efficiency. 
                  With a focus on secure data integration, we synchronize and share information seamlessly between systems, fostering collaboration and informed decision-making. 
                  Trust Kloudping for straightforward, expert solutions that seamlessly connect your applications, processes, and data.
                  </div>
                  <div className="more-link">
                    <Link to="Migration">
                      <span className="fa fa-arrow-right"></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div> */}

            <div className= "service-blocks col-lg-4 col-md-6 col-sm-12">
              <div
                className="service-block wow fadeInUp"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box">
                  <div className="icon-outer">
                    <span className="icon-bg"></span>
                    <div className="icon-box">
                      <img
                        src={require("../../images/icons/services/4.png")}
                        alt="services-4"
                        title=""
                      />
                    </div>
                  </div>

                  <h3>
                    <Link to="Operation">Cloud Computing</Link>
                  </h3>
                  <div className="text">
                  At Kloudping IT Solutions, we redefine Cloud Operations through cutting-edge automation and orchestration techniques. Our expert team excels in monitoring, deployment automation, and streamlining cloud operations to enhance efficiency. With a focus on precision, we orchestrate seamless workflows, ensuring a robust and responsive cloud infrastructure. From real-time monitoring automation to deploying efficient pipelines, we navigate the complexities of cloud deployments effortlessly. 
                  Kloudping is your trusted partner for unlocking the full potential of the cloud, optimizing operations, and driving excellence in every facet of your Cloud journey. {" "}
                  </div>
                  <div className="more-link">
                    <Link to="Operation">
                      <span className="fa fa-arrow-right"></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className= "service-blocks col-lg-4 col-md-6 col-sm-12">
              <div
                className="service-block wow fadeInUp"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box">
                  <div className="icon-outer">
                    <span className="icon-bg"></span>
                    <div className="icon-box">
                      <img
                        src={require("../../images/icons/services/5.png")}
                        alt="services-5"
                        title=""
                      />
                    </div>
                  </div>
                  <h3>
                    <Link to="SAAS">Web/App Development</Link>
                  </h3>
                  <div className="text">
                  Collaborating on SAAS product development involves expertise in Cloud Infrastructure, Microservices Architecture, Containerization, and Orchestration.
                   Our approach integrates DevOps Practices, navigates Serverless Computing, manages Data effectively, and emphasizes secure API Development. 
                   With a focus on Cloud Security and Cost Optimization, our collaboration aims to create a robust and efficient SAAS product. 
                   Trust in our experience with Cloud Computing, Microservices, and DevOps for a well-rounded development process.
                   Explore the benefits of Containerized solutions and orchestrated services, ensuring a secure and cost-effective product development journey.
                  </div>
                  <div className="more-link">
                    <Link to="businesssecurity">
                      <span className="fa fa-arrow-right"></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bottom-text">
            <div className="text">
              We’re ready to discover and unlock your potential.{" "}
              <Link>
              <Link to="/contact" >
                <strong>Get The Solutions</strong>
              </Link></Link>{" "}
              or Call us Today!{" "}
              <a href="tel: +91 89035 00146">
                <strong> +91 89035 00146</strong>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="locations-section">
        <div className="auto-container">
          <div className="sec-title centered">
            <div className="upper-text">Kloudping IT Services</div>
            <h2>Kloudping Delivering World-Class Projects to Every Corner of the Globe</h2>
            <p>With Over 18+ Successful Projects Delivered Globally, Kloudping 
            Sets the Standard for Excellence.</p>

            {/* <div className="lower-text">
              We value customer experience as a universal standard and we adapt to the local needs and preferences of each market.
              {" "}
            </div> */}
          </div>
          <div className="map-box">
            <div className="map-image">
              <img
                src={require("../../images/icons/map-pattern.png")}
                alt="map-pattern"
                title=""
              />
            </div>

            <div className="location-point loc-one">
              <div className="inner">
                4+ Projects in  TamilNadu , India
              </div>
            </div>

            <div className="location-point loc-two">
              <div className="inner">
                4+ Projects in Maharashtra , India
              </div>
            </div>

            <div className="location-point loc-three">
              <div className="inner">
                2+ Projects in USA
              </div>
            </div>

            <div className="location-point loc-four">
              <div className="inner">
                5+ Projects in Odisha , India
              </div>
            </div>

            <div className="location-point loc-five">
            {/* <div className="icon-outer"> */}
            {/* <img 
              style={{ width: '200px',height:'100px' } }
                src={require("../../images/flags/mal1.jpg")}
                alt="malaysia"
                title=""
              /> */}
            {/* </div> */}
              <div className="inner">
                5+ Projects in  Malaysia
              </div>
            </div>

            {/*-- <div className="location-point loc-five">
                  <div className="inner">100+ Projects Done For Local IT Companies</div>
              </div>
              
              <div className="location-point loc-six">
                  <div className="inner">100+ Projects Done For Local IT Companies</div>
              </div>
              
              <div className="location-point loc-seven">
                  <div className="inner">100+ Projects Done For Local IT Companies</div>
              </div>
              
              <div className="location-point loc-eight">
                  <div className="inner">100+ Projects Done For Local IT Companies</div>
              </div>
              
              
              
             <div className="location-point loc-nine">
                  <div className="inner">100+ Projects Done For Local IT Companies</div>
              </div>

              
              <div className="location-point loc-ten">
                  <div className="inner">100+ Projects Done For Local IT Companies</div>
              </div>
              
              <div className="location-point loc-eleven">
                  <div className="inner">100+ Projects Done For Local IT Companies</div>
              </div>
              
              <div className="location-point loc-twelve">
                  <div className="inner">100+ Projects Done For Local IT Companies</div>
              </div> */}
          </div>
        </div>
      </section>

      {/* <section className="default-bg-section">
        <div
          className="image-layer"
          style={{ background: `url(${Background})` }}
        ></div>

        <div className="auto-container">
          <div className="content-box">
            <h2>
              Our Cloud Solutions will get you on move towards your destination
              faster than rivals in a more reliable way!
            </h2>
            <div className="link-box">
              <Link to="services" className="theme-btn btn-style-one">
                <div className="btn-title">Proven IT Solutions</div>
              </Link>
            </div>
          </div>
        </div>
      </section> */}

      <section className="fun-facts-section">
        <div className="auto-container">
          <div className="fact-counter">
            <div className="row clearfix">
              <div className="counter-column col-lg-4 col-md-4 col-sm-12">
                <div className="inner wow fadeInUp" data-wow-delay="0ms">
                  <div className="upper-title">Projects Worked On</div>
                  <div className="count-box">
                    <CountUp
                      className="count-text"
                      end={15}
                      enableScrollSpy="true"
                      scrollSpyOnce="true"
                    >
                      0
                    </CountUp>
                    +
                  </div>
                  <div className="counter-title">for customers</div>
                </div>
              </div>

              <div className="counter-column col-lg-4 col-md-4 col-sm-12 wow fadeInUp">
                <div className="inner wow fadeInUp" data-wow-delay="300ms">
                  <div className="upper-title">Powerful Team to Focus</div>
                  <div className="count-box">
                    <CountUp
                      className="count-text"
                      end={12}
                      enableScrollSpy="true"
                      scrollSpyOnce="true"
                    >
                      0
                    </CountUp>
                    +
                  </div>
                  <div className="counter-title">Expert Members</div>
                </div>
              </div>

              <div className="counter-column col-lg-4 col-md-4 col-sm-12 wow fadeInUp">
                <div className="inner wow fadeInUp" data-wow-delay="600ms">
                  <div className="upper-title">Launched Case Studies</div>
                  <div className="count-box">
                    <CountUp
                      className="count-text"
                      end={3}
                      enableScrollSpy="true"
                      scrollSpyOnce="true"
                    >
                      0
                    </CountUp>
                  </div>
                  <div className="counter-title">Fully Launched</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="theme-separator"></div>

      {/* <section className="cases-section">
        <div className="gradient-layer"></div>

        <div className="auto-container">
          <div className="sec-title centered">
            <div className="upper-text">Our Case Studies</div>
            <h2>
              <strong>IT Solutions & Projects</strong>
            </h2>
            <div className="lower-text">
              Cloud Computing is responding well to the changing needs of the
              current times. Those needs are either be of businesses or
              educational institutions like colleges, schools which have to be
              fulfilled with cost-effective and scalable solutions.
            </div>
          </div>

          <div className="carousel-box">
            <OwlCarousel
              className="cases-carousel theme-carousel owl-theme owl-carousel"
              loop="true"
              margin={30}
              autoheight="true"
              lazyload="true"
              nav="true"
              dots={false}
              autoplay="true"
              autoplayTimeout={7000}
              smartSpeed={700}
              items={2}
            >
              <div className="slide-item">
                <div className="case-block">
                  <div className="inner-box">
                    <figure className="image-box">
                      <Link to="casestudies">
                        <img
                          src={require("../../images/resource/case-image-1.webp")}
                          alt="case-image-1"
                          title=""
                        />
                      </Link>
                    </figure>
                    <div className="content-box">
                      <div className="title-box">
                        <h4>
                          <Link to="casestudies">Workflow Management</Link>
                        </h4>
                        <div className="sub-text">IT Networking</div>
                      </div>
                      <div className="text-content">

                        <Link to="casestudies">Workflow Management</Link>

                        <div className="text">
                          Workflow management is the discipline of creating,
                          documenting,monitoring and improving upon theseries of steps, or workflow, that is required to
                          complete a specific task.{" "}
                        </div>
                        <div className="link-box">
                          <Link to="casestudies">
                            View Case Study{" "}
                            <span className="arrow fa fa-arrow-right"></span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="slide-item">
                <div className="case-block">
                  <div className="inner-box">
                    <figure className="image-box">
                      <Link to="casestudies">
                        <img
                          src={require("../../images/resource/case-image-1.webp")}
                          alt="case-image-1"
                          title=""
                        />
                      </Link>
                    </figure>
                    <div className="content-box">
                      <div className="title-box">
                        <h4>
                          <Link to="casestudies">Data MachineLearning</Link>
                        </h4>
                        <div className="sub-text"> Artificial Intelligence</div>
                      </div>
                      <div className="text-content">

                        <Link to="casestudies">Data Machine Learning</Link>

                        <div className="text">
                          Data cloud is a crucial component in the field of
                          Machine Learning.It refers to the set of
                          observations or measurements that can be used to
                          train a machine-learning model.{" "}
                        </div>
                        <div className="link-box">
                          <Link to="casestudies">
                            View Case Study{" "}
                            <span className="arrow fa fa-arrow-right"></span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="slide-item">
                <div className="case-block">
                  <div className="inner-box">
                    <figure className="image-box">
                      <Link to="casestudies">
                        <img
                          src={require("../../images/resource/case-image-1.webp")}
                          alt="case-image-1"
                          title=""
                        />
                      </Link>
                    </figure>
                    <div className="content-box">
                      <div className="title-box">
                        <h4>
                          <Link to="casestudies">Workflow Management</Link>
                        </h4>
                        <div className="sub-text">IT Networking</div>
                      </div>
                      <div className="text-content">
                        <Link to="casestudies">Workflow Management</Link>
                        <div className="text">
                          Workflow management is the discipline of creating,
                          documenting, monitoring and improving upon the
                          series of steps, or workflow, that is required to
                          complete a specific task.

                        </div>
                        <div className="link-box">
                          <Link to="casestudies">
                            View Case Study{" "}
                            <span className="arrow fa fa-arrow-right"></span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="slide-item">
                <div className="case-block">
                  <div className="inner-box">
                    <figure className="image-box">
                      <Link to="casestudies">
                        <img
                          src={require("../../images/resource/case-image-2.jpg")}
                          alt="case-image-2"
                          title=""
                        />
                      </Link>
                    </figure>
                    <div className="content-box">
                      <div className="title-box">
                        <h4>
                          <Link to="casestudies">Data Machine Learning</Link>
                        </h4>
                        <div className="sub-text">
                          Artificial Intelligence
                        </div>
                      </div>
                      <div className="text-content">

                        <Link to="casestudies">Data Machine Learning</Link>

                        <div className="text">
                          Data cloud is a crucial component in the field of
                          Machine Learning.It refers to the set of
                          observations or measurements that can be used to
                          train a machine-learning model.
                        </div>
                        <div className="link-box">
                          <Link to="casestudies">
                            View Case Study{" "}
                            <span className="arrow fa fa-arrow-right"></span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>

          <div className="bottom-text">
            <div className="text">
              We’re ready to discover and unlock your potential.{" "}
              <strong>Call us Today!</strong> &nbsp;
              <a href="tel: +91 89035 00146">
                <strong> +91 89035 00146</strong>
              </a>
            </div>
          </div>
        </div>
      </section> */}

      <section className="appointment-section">
        <div className="pattern-layer"></div>

        <div className="auto-container">
          <div className="row clearfix">
            <div className="text-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner">
                <div className="sec-title">
                  <div className="line" >
                    Kloudping - Welcome to IT Solutions
                  </div>
                  <h2>
                    Why Choose <strong> Kloudping IT Solutions</strong>
                  </h2>
                  <div className="lower-text">
                    gives you access to top-notch IT administrators with
                    specific skill sets, negating the need to find and hire
                    them yourself.
                  </div>
                </div>

                <div className="content">
                  <div className="service-block-two">
                    <div className="inner-box">
                      <div className="icon-outer">
                        <span className="icon-bg"></span>
                        <div className="icon-box">
                          <img
                            src={require("../../images/icons/services/6.png")}
                            alt="services-6"
                            title=""
                          />
                        </div>
                      </div>
                      <h3>
                        <Link to="services">Easier To Implement</Link>
                      </h3>
                      <div className="text">
                        the strategic approach to helping an organization
                        adopt new procedures
                      </div>
                    </div>
                  </div>

                  <div className="service-block-two">
                    <div className="inner-box">
                      <div className="icon-outer">
                        <span className="icon-bg"></span>
                        <div className="icon-box">
                          <img
                            src={require("../../images/icons/services/7.png")}
                            alt="services-7"
                            title=""
                          />
                        </div>
                      </div>
                      <h3>
                        <Link to="services">Increase Growth Rates</Link>
                      </h3>
                      <div className="text">
                        measures its change from one period to another (month,
                        quarter, year). It is very generally expressed as a
                        percentage
                      </div>
                    </div>
                  </div>

                  <div className="service-block-two">
                    <div className="inner-box">
                      <div className="icon-outer">
                        <span className="icon-bg"></span>
                        <div className="icon-box">
                          <img
                            src={require("../../images/icons/services/8.png")}
                            alt="services-8"
                            title=""
                          />
                        </div>
                      </div>
                      <h3>
                        <Link to="services">Branding Of The Future</Link>
                      </h3>
                      <div className="text">
                        Branding Future is a venture engaged in the business
                        of providing services to businesses such as Branding,
                        Marketing, Public Relations as well as Cloud
                        Management.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-column col-lg-6 col-md-12 col-sm-12">
              <div
                className="inner wow fadeInRight"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <figure className="image">
                  <img
                    src={require("../../images/resource/featured-image-1.jpg")}
                    alt="featured-image-1"
                    title=""
                  />
                </figure>

                <div className="form-box">
                  <div className="title">
                    <h3>Book Your Appointment</h3>
                    <div className="text">
                      We Help Customers Digital Transformation By IT Solutions
                    </div>
                  </div>
                  <div className="default-form appointment-form">
                    <form method="post" action="">
                      <div className="row clearfix">
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group txtOnly">
                          <input
                            type="text"
                            name="field-name"
                            placeholder="First Name"
                            required=""
                            defaultValue=""
                          />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group txtOnly">
                          <input
                            type="text"
                            name="field-name"
                            placeholder="Last Name"
                            required=""
                            defaultValue=""
                          />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                          <input
                            type="email"
                            name="field-name"
                            placeholder="Email"
                            required=""
                            defaultValue=""
                          />
                        </div>

                        <div className="form-group col-md-6 col-sm-12">
                          <select
                            className="custom-select-box"
                            name="field-name"
                          >
                            a  <option>Inquiry About</option>
                            <option>IT Cloud Integration</option>
                            <option>Operation</option>
                            <option>Product Engineering</option>
                          </select>
                        </div>

                        <div className="form-group col-md-12 col-sm-12">
                          <Link to="/contact" className="theme-btn btn-style-one">

                            <span className="btn-title"> Make a Request</span>
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="testimonials-section">
        <div
          className="image-layer"
          // style={{
          //   background: `url(${Background2})`,
          //   backgroundAttachment: "fixed",
          //   backgroundRepeat: "no-repeat",
          //   backgroundSize: "100%",
          //   right:'7%'
          // }}
        ></div>                                                 
        <div className="auto-container" >
          <div
            className="carousel-box wow fadeInUp"
            data-wow-delay="0ms"
            data-wow-duration="1500ms"
            style={{maxWidth:'1000px'}}
          >
            <OwlCarousel
              className="testimonial-carousel theme-carousel owl-theme owl-carousel"
              loop="true"
              margin={0}
              autoheight="true"
              lazyload="true"
              nav="true"
              dots="true"
              autoplay="true"
              autoplayTimeout={5000}
              smartSpeed={500}
              items={1}
            >
              <div className="slide-item">
                <div className="inner">
                  <div className="icon-box">
                    <span className="flaticon-chat-2"></span>
                  </div>
                  <div className="title">
                    Best Services from Kloudping IT Solutions!
                  </div>
                  <div className="text">
                    As technology continues to drive the business world,
                    having a strong IT foundation is essential for the success
                    and survival of organizations. When you work with the best
                    IT services companies in India, you make your business
                    technology competent and get a significant edge over your
                    competitors.
                  </div>
                  <div className="info">
                    <div className="name"> Teena</div>
                    <div className="designation">CEO, HR Manager</div>
                  </div>
                </div>
              </div>

              <div className="slide-item">
                <div className="inner">
                  <div className="icon-box">
                    <span className="flaticon-chat-2"></span>
                  </div>
                  <div className="title">
                    Best Services from Kloudping IT Solutions!
                  </div>
                  <div className="text">
                    IT solutions is that they provide the opportunity to use
                    the latest technology in the market.
                  </div>
                  <div className="info">
                    <div className="name"> Teena</div>
                    <div className="designation">CEO, HR Manager</div>
                  </div>
                </div>
              </div>

              <div className="slide-item">
                <div className="inner">
                  <div className="icon-box">
                    <span className="flaticon-chat-2"></span>
                  </div>
                  <div className="title">
                    Best Services from Kloudping IT Solutions!
                  </div>
                  <div className="text">
                    Services include consulting, software development, systems
                    integration, as well as hardware deployment and education.
                  </div>
                  <div className="info">
                    <div className="name"> Teena</div>
                    <div className="designation">CEO, HR Manager</div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>

      

      <section className="news-section">
        <div className="auto-container">
          <div className="sec-title centered">
            <div className="upper-text">Read Kloudping IT News</div>
            <h2>Latest News & Articles</h2>
            <div className="lower-text">
              This will allow organizations to more easily take advantage of
              the best features and services of each provider. The growth of
              edge computing
            </div>
          </div>
          <div className="upper-row">
            <div className="row clearfix">
              <div className="news-block col-lg-4 col-md-6 col-sm-12">
                <div
                  className="inner-box wow fadeInUp"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                >
                  <div className="image-box">
                    <Link to="blogfullPost">
                      <img
                        src={require("../../images/resource/blog-image-1.jpg")}
                        alt="blog-image-1"
                        title=""
                      />
                    </Link>
                  </div>
                  <div className="lower-box">
                    <div className="category">IT Projects</div>
                    <h3>
                      <Link to="blogfullPost">
                        10 Efficient & Measurable Benefits of Software
                      </Link>
                    </h3>
                    <div className="meta-info">
                      <ul className="clearfix">
                        <li>
                          <Link to="#">By Admin</Link>
                        </li>
                        <li>
                          <Link to="#">24 Jan 2023</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="more-link">
                      <Link to="blogfullPost">
                        <span className="fa fa-arrow-right"></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="news-block col-lg-4 col-md-6 col-sm-12">
                <div
                  className="inner-box wow fadeInUp"
                  data-wow-delay="300ms"
                  data-wow-duration="1500ms"
                >
                  <div className="image-box">
                    <Link to="blogfullPost">
                      <img
                        src={require("../../images/resource/blog-image-2.jpg")}
                        alt="blog-image-2"
                        title=""
                      />
                    </Link>
                  </div>
                  <div className="lower-box">
                    <div className="category">Mobile Apps</div>
                    <h3>
                      <Link to="blogfullPost">
                        Native or Cross-Platform: Mobile Development
                      </Link>
                    </h3>
                    <div className="meta-info">
                      <ul className="clearfix">
                        <li>
                          <Link to="#">By Admin</Link>
                        </li>
                        <li>
                          <Link to="#">24 Jan 2023</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="more-link">
                      <Link to="blogfullPost">
                        <span className="fa fa-arrow-right"></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="news-block col-lg-4 col-md-6 col-sm-12">
                <div
                  className="inner-box wow fadeInUp"
                  data-wow-delay="600ms"
                  data-wow-duration="1500ms"
                >
                  <div className="image-box">
                    <Link to="blogfullPost">
                      <img
                        src={require("../../images/resource/blog-image-3.jpg")}
                        alt="blog-image-3"
                        title=""
                      />
                    </Link>
                  </div>
                  <div className="lower-box">
                    <div className="category">Development</div>
                    <h3>
                      <Link to="blogfullPost">
                        Vital Tips For Blockchain Software Product
                      </Link>
                    </h3>
                    <div className="meta-info">
                      <ul className="clearfix">
                        <li>
                          <Link to="#">By Admin</Link>
                        </li>
                        <li>
                          <Link to="#">24 Jan 2023</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="more-link">
                      <Link to="blogfullPost">
                        <span className="fa fa-arrow-right"></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="lower-row">
            <div className="row clearfix">
              <div className="news-block-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image-box">
                    <Link to="BlogFullPost"><img src={require('../../images/resource/blog-image-4.jpg')} alt="blog-image-4" title="" /></Link>
                  </div>
                  <div className="lower-box">
                    <div className="category">Security News</div>
                    <h3>
                      <Link to="blogfullPost">
                        Data Security with multiple business values
                      </Link>
                    </h3>
                    <div className="meta-info">
                      <ul className="clearfix">
                        <li>
                          <Link to="#">By Admin</Link>
                        </li>
                        <li>
                          <Link to="#">24 Jan 2023</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="news-block-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image-box">
                    <Link to="BlogFullPost"><img src={require('../../images/resource/blog-image-5.jpg')} alt="blog-image-5" title="" /></Link>
                  </div>
                  <div className="lower-box">
                    <div className="category">Development News</div>
                    <h3>
                      <Link to="blogfullPost">
                        Leverage the full spectrum of technology stacks
                      </Link>
                    </h3>
                    <div className="meta-info">
                      <ul className="clearfix">
                        <li>
                          <Link to="#">By Admin</Link>
                        </li>
                        <li>
                          <Link to="#">24 Jan 2023</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="news-block-two col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image-box">
                    <Link to="BlogFullPost"><img src={require('../../images/resource/blog-image-6.jpg')} alt="blog-image-6" title="" /></Link>
                  </div>
                  <div className="lower-box">
                    <div className="category">IT Sector News</div>
                    <h3>
                      <Link to="blogfullPost">
                        Commercial apps multi-platform and multi-device
                        strategy
                      </Link>
                    </h3>
                    <div className="meta-info">
                      <ul className="clearfix">
                        <li>
                          <Link to="#">By Admin</Link>
                        </li>
                        <li>
                          <Link to="#">24 Jan 2023</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>

      {/*<section className="sponsors-section">
      <div className="sponsors-outer">
        
          <div className="auto-container">
              
          <OwlCarousel className="sponsors-carousel theme-carousel owl-theme owl-carousel" loop= "true" margin= {30} autoheight="true" lazyload="true" nav="true" dots="true" autoplay= "true" autoplayTimeout={6000} smartSpeed={500} items={5}>
                  <div className="slide-item"><figure className="image-box"><a href="#"><img src={require('../../images/clients/1.jpg')} alt="clients-1"/></a></figure></div>
                  <div className="slide-item"><figure className="image-box"><a href="#"><img src={require('../../images/clients/2.jpg')} alt="clients-2"/></a></figure></div>
                  <div className="slide-item"><figure className="image-box"><a href="#"><img src={require('../../images/clients/3.jpg')} alt="clients-3"/></a></figure></div>
                  <div className="slide-item"><figure className="image-box"><a href="#"><img src={require('../../images/clients/4.jpg')} alt="clients-4"/></a></figure></div>
                  <div className="slide-item"><figure className="image-box"><a href="#"><img src={require('../../images/clients/5.jpg')} alt="clients-5"/></a></figure></div>
                  <div className="slide-item"><figure className="image-box"><a href="#"><img src={require('../../images/clients/1.jpg')} alt="clients-1"/></a></figure></div>
                  <div className="slide-item"><figure className="image-box"><a href="#"><img src={require('../../images/clients/2.jpg')} alt="clients-2"/></a></figure></div>
                  <div className="slide-item"><figure className="image-box"><a href="#"><img src={require('../../images/clients/3.jpg')} alt="clients-3"/></a></figure></div>
                  <div className="slide-item"><figure className="image-box"><a href="#"><img src={require('../../images/clients/4.jpg')} alt="clients-4"/></a></figure></div>
                  <div className="slide-item"><figure className="image-box"><a href="#"><img src={require('../../images/clients/5.jpg')} alt="clients-5"/></a></figure></div>
              </OwlCarousel>
          </div>
      </div>
  </section>*/}
    
  
    </>

    
    
  );
}
}

export default home;
