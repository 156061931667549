import React,{ Component  }  from "react";
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import config from "./config";
import MessageParser from "./MessageParser";
import ActionProvider from "./ActionProvider";
import chatbot from "../../images/chatbot.png";
import StartBtn from "./Bot/StartBtn";
class MyChatbot extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      chatbotVisible: false,
    };
   
  }
 
  toggleChatbot = () => {
    this.setState((prevState) => ({
      chatbotVisible: !prevState.chatbotVisible,
    }));
  };
  render() {
    const { chatbotVisible } = this.state;
    const validateInput = (input) => {debugger;
    
      return input.trim() !== ''; // Example: input should not be empty
    };
  
    return (
      <div style={{ position: "relative" }}>
        <img
          src={chatbot}
          alt="Chat Bot Logo"
          style={{
            position: "fixed",
            right: "25px",
            bottom: "50px",
            width: "70px",
            cursor: "pointer",

            zIndex: 2,
          }}
          onClick={this.toggleChatbot}
        />

        {/* Chat bot component (conditionally rendered) */}
        {chatbotVisible && (
          <div
            style={{
              position: "fixed",

              right: "62px",
              bottom: "102px",
              zIndex: 100000,

              cursor: "pointer",

              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <Chatbot
              config={config}
              messageParser={MessageParser}
              actionProvider={ActionProvider}
              validator={validateInput}
            />
          </div>
        )}
      </div>
    );
  }
}
export default MyChatbot;
