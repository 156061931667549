import React , { Component }from 'react'
import Background7 from '../../images/background/services.jpg';
import Background8 from '../../images/background/image-3.jpg';
import Background9 from '../../images/background/image-2.jpg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import $ from "jquery";

class service extends Component {
    componentDidMount() {
        $(".txtOnly").bind("keypress", function (event) {
            if (event.charCode!=0) {
                var regex = new RegExp("^[a-zA-Z]+$");
                var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
                if (!regex.test(key)) {
                    event.preventDefault();
                    return false;
                }
            }
        });
        $(".numberOnly").bind("keypress", function (event) {
            if (event.charCode!=0) {
                var regex = new RegExp("^[0-9]+$");
                var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
                if (!regex.test(key)) {
                    event.preventDefault();
                    return false;
                }
            }
        });
       }
       
    render() {
        return (
            <>
   <section className="inner-banner alternate">
        <div className="image-layer"  style={{ background: `url(${Background7})`,backgroundRepeat: "no-repeat",   backgroundPosition: "0% 55%", backgroundSize: "110% 220%"}}      ></div>
		<div className="auto-container">
            <div className="inner">
    			<div className="title-box">
                    <h1>Services </h1>
                    <div className="d-text">Services we offer</div>
                </div>
            </div>
		</div>
    </section>
    

    <section className="services-section">
        <div className="gradient-layer"></div>

        <div className="auto-container">
        <div className="sec-title centered">
                            <div className="upper-text">Kloudping - Services we offer</div>
                            
                        </div>

                        <div className="upper-row">
                            <div className="row clearfix">
                 
                                <div className="text-column col-lg-12 col-md-12 col-sm-12">
                                    <div className="inner">
                                        <div className="text">
                                            <p>At Kloudping, we're dedicated to transforming your digital aspirations into reality. 
                                                As a leading cloud services provider, we specialize in delivering exceptional software solutions that cater to the diverse needs of small to large businesses. 
                                                Our commitment to innovation, quality, and cutting-edge technologies has made us the go-to choice for businesses. 

                                           </p>
                                        </div>
                                    </div>
                                </div>
                 
                               
                            </div>
                        </div>
            <div className="row clearfix">
                
              
                <div className="column col-lg-4 col-md-12 col-sm-12">
                    <div className="sec-title wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                        <div className="upper-text">Kloudping IT Services</div>
                        <h2><strong>Solutions And Focus Areas</strong></h2>
                        <div className="lower-text">Digital Transformation Through Cloud Implementation.</div>
                    </div>

                    
                    <div className="service-block wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                        <div className="inner-box">
                            <div className="icon-outer">
                                <span className="icon-bg"></span>
                                <div className="icon-box"><img src={require('../../images/icons/services/1.png')}alt="services-1" title=""/></div>
                            </div>
                            <h3><a href='/datainfrastructure'>DevOps</a></h3>
                            <div className="text">At Kloudping IT Solutions, we committed to fostering collaboration and communication between your development and operations teams. 
                            Our mission is to automate manual processes, enhancing efficiency, reliability, and speed in software delivery. 
                            From Continuous Integration (CI) to Continuous Delivery/Deployment (CD), along with comprehensive Automation, Collaboration, Communication, and Testing, 
                            we ensure seamless DevOps services. Experience a transformative journey as we leverage our expertise to streamline processes and create a robust, efficient cloud environment. 
                            Welcome to a future where Kloudping IT Solutions makes your cloud aspirations a reality..</div>
                            <div className="more-link"><a href='/datainfrastructure'><span className="fa fa-arrow-right"></span></a></div>
                        </div>
                    </div>

                </div>

              
                <div className="column col-lg-4 col-md-12 col-sm-12">

                    
                    <div className="service-block wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                        <div className="inner-box">
                            <div className="icon-outer">
                                <span className="icon-bg"></span>
                                <div className="icon-box"><img src={require('../../images/icons/services/2.png')}alt="services-2" title=""/></div>
                            </div>
                            <h3><a href='/cloudintegration'>IT Cloud Migration</a></h3>
                            <div className="text">Explore comprehensive cloud migration services encompassing infrastructure migration, data migration, application migration, and platform migration, 
                  all tailored for seamless optimization. Our expertise extends to executing successful cloud migrations, integrating careful planning, security considerations, 
                  and compliance requirements. With a commitment to ongoing management, we guarantee an optimized cloud environment that precisely meets the dynamic needs of your business. 
                  Trust us for a holistic approach to cloud migration, ensuring a smooth transition and enhanced operational efficiency.</div>
                            <div className="more-link"><a href='/cloudintegration'><span className="fa fa-arrow-right"></span></a></div>
                        </div>
                    </div>

                    
                    <div className="service-block wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                        <div className="inner-box">
                            <div className="icon-outer">
                                <span className="icon-bg"></span>
                                <div className="icon-box"><img src={require('../../images/icons/services/3.png')}alt="services-3" title=""/></div>
                            </div>
                            <h3><a href='/itstartup'>IT Cloud Intrgration</a></h3>
                            <div className="text"> Kloudping IT Solutions specializes in pragmatic cloud integration, connecting diverse software applications into a unified system for tailored functionality. 
                  Our expertise extends to streamlining workflows and business processes across various cloud environments, promoting operational efficiency. 
                  With a focus on secure data integration, we synchronize and share information seamlessly between systems, fostering collaboration and informed decision-making. 
                  Trust Kloudping for straightforward, expert solutions that seamlessly connect your applications, processes, and data.
                  </div>
                            <div className="more-link"><a href='/itstartup'><span className="fa fa-arrow-right"></span></a></div>
                        </div>
                    </div>

                </div>

              
                <div className="column col-lg-4 col-md-12 col-sm-12">
                    
                    <div className="service-block wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                        <div className="inner-box">
                            <div className="icon-outer">
                                <span className="icon-bg"></span>
                                <div className="icon-box"><img src={require('../../images/icons/services/4.png')}  alt="services-4" title=""/></div>
                            </div>
                            <h3><a href='/Operation'>Cloud Operation</a></h3>
                            <div className="text"> At Kloudping IT Solutions, we redefine Cloud Operations through cutting-edge automation and orchestration techniques. Our expert team excels in monitoring, deployment automation, and streamlining cloud operations to enhance efficiency. With a focus on precision, we orchestrate seamless workflows, ensuring a robust and responsive cloud infrastructure. From real-time monitoring automation to deploying efficient pipelines, we navigate the complexities of cloud deployments effortlessly. 
                  Kloudping is your trusted partner for unlocking the full potential of the cloud, optimizing operations, and driving excellence in every facet of your Cloud journey. {" "}</div>
                            <div className="more-link"><a href='/Operation'><span className="fa fa-arrow-right"></span></a></div>
                        </div>
                    </div>

                    
                    <div className="service-block wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                        <div className="inner-box">
                            <div className="icon-outer">
                                <span className="icon-bg"></span>
                                <div className="icon-box"><img src={require('../../images/icons/services/5.png')} alt="services-5" title=""/></div>
                            </div>
                            <h3><a href='/businesssecurity'>SAAS Product Development</a></h3>
                            <div className="text">Collaborating on SAAS product development involves expertise in Cloud Infrastructure, Microservices Architecture, Containerization, and Orchestration.
                   Our approach integrates DevOps Practices, navigates Serverless Computing, manages Data effectively, and emphasizes secure API Development. 
                   With a focus on Cloud Security and Cost Optimization, our collaboration aims to create a robust and efficient SAAS product. 
                   Trust in our experience with Cloud Computing, Microservices, and DevOps for a well-rounded development process.
                   Explore the benefits of Containerized solutions and orchestrated services, ensuring a secure and cost-effective product development journey.</div>
                            <div className="more-link"><a href='/businesssecurity'><span className="fa fa-arrow-right"></span></a></div>
                        </div>
                    </div>

                </div>

            </div>

            <div className="bottom-text">
                <div className="text">We’re ready to discover and unlock your potential. <a href="/services"><strong>Get The Solutions</strong></a> or Call us Today! <a href="tel:+91 8903500146"><strong>+91 8903500146</strong></a></div>
            </div>

        </div>
    </section>

    <section className="video-section alternate">
        <div className="image-layer" style={{ background: `url(${Background8})`}}   ></div>
        <div className="auto-container">
            <div className="video-link">
                <a href="https://youtu.be/6eCAGcYdKiw?si=QFrz010lMey3BKj8" className="theme-btn lightbox-image"><span className="flaticon-play-button"></span></a>
            </div>
            <h2>Kloudping IT Solutions will get you on the move towards your goals.</h2>
        </div>
    </section>

    <section className="services-section-three">

<div className="auto-container">
    <div className="sec-title centered">
        <div className="upper-text">IT Related  CaseStudies & Works</div>
        <h2><strong>Prominent IT Solutions</strong></h2>
    </div>
    <div className="services-box">
        <div className="row clearfix">
          
            <div className="service-block-four col-lg-6 col-md-6 col-sm-12">
                <div className="inner-box">
                    <div className="icon-outer">
                        <span className="icon-bg"></span>
                        <div className="icon-box"><span className="themify-crown"></span></div>
                    </div>
                    <h3><a href='/#Services'>Pure Agile Methodology</a></h3>
                    <div className="text">Cross functional teams, meaning everyone needed in order to get completed work into production. There is a funnel of work feeding into the team that has been prioritised into value order, broken down as features.</div>
                </div>
            </div>
          
            <div className="service-block-four col-lg-6 col-md-6 col-sm-12">
                <div className="inner-box">
                    <div className="icon-outer">
                        <span className="icon-bg"></span>
                        <div className="icon-box"><span className="themify-bar-chart-alt"></span></div>
                    </div>
                    <h3><a href='/#Services'>Passionate About Success</a></h3>
                    <div className="text">Passion gets you to do the things necessary to grow and improve. Instead of going through the motions in life, you will push on and work harder to solve problems. You will learn what it really takes to be successful.</div>
                </div>
            </div>
          
            <div className="service-block-four col-lg-6 col-md-6 col-sm-12">
                <div className="inner-box">
                    <div className="icon-outer">
                        <span className="icon-bg"></span>
                        <div className="icon-box"><span className="themify-settings"></span></div>
                    </div>
                    <h3><a href='/#Services'>Global Technology Services</a></h3>
                    <div className="text">We provide creative solutions to clients around the world, creating things that get attention and meaningful..</div>
                </div>
            </div>
          
            <div className="service-block-four col-lg-6 col-md-6 col-sm-12">
                <div className="inner-box">
                    <div className="icon-outer">
                        <span className="icon-bg"></span>
                        <div className="icon-box"><span className="themify-flag-alt"></span></div>
                    </div>
                    <h3><a href='/#Services'>Solve Customer’s IT Problems</a></h3>
                    <div className="text">Identifying a problem, developing possible solution paths, and taking the appropriate course of action.</div>
                </div>
            </div>
          
            <div className="service-block-four col-lg-6 col-md-6 col-sm-12">
                <div className="inner-box">
                    <div className="icon-outer">
                        <span className="icon-bg"></span>
                        <div className="icon-box"><span className="themify-world"></span></div>
                    </div>
                    <h3><a href='/#Services'>Work With Many Partners </a></h3>
                    <div className="text">
A strategic partnership is a mutually beneficial arrangement between two separate companies that do not directly compete with one another. Companies have long been engaging in strategic partnerships to enhance their offers and offset costs.</div>
                </div>
            </div>
          
            <div className="service-block-four col-lg-6 col-md-6 col-sm-12">
                <div className="inner-box">
                    <div className="icon-outer">
                        <span className="icon-bg"></span>
                        <div className="icon-box"><span className="themify-panel"></span></div>
                    </div>
                    <h3><a href='/#Services'>Core defaultValues and Missions</a></h3>
                    <div className="text">company’s core values can function as a kind of mission statement for the company, serving as common goals and guiding principles that inform company culture, product development, and the overall work environment..</div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>

<div className="theme-separator"></div>

<section className="cases-section">
        <div className="gradient-layer"></div>

        <div className="auto-container">

            <div className="sec-title centered">
                <div className="upper-text">Our Case Studies</div>
                <h2><strong>IT Solutions & Projects</strong></h2>
                <div className="lower-text">Sit amet consectetur adipisicing elitm sed eiusmod temp sed incididunt labore dolore magna aliquatenim veniam quis ipsum nostrud exer citation ullamco laboris.</div>
            </div>

       
            <div className="carousel-box">
            <OwlCarousel className="cases-carousel theme-carousel owl-theme owl-carousel" loop="true" margin= {40} autoheight="true" lazyload="true" nav= "true" dots= {false} autoplay= "true" autoplayTimeout= {7000} smartSpeed= {700} items={2} >
                   
                    <div className="slide-item">
                 
                        <div className="case-block">
                            <div className="inner-box">
                                <figure className="image-box">
                                    <a href="/casestudies"><img src={require('../../images/resource/case-image-1.webp')}alt="case-image-1" title=""/></a>
                                </figure>
                                <div className="content-box">
                                    <div className="title-box">
                                        <h4><a href='/casestudies'>Workflow Management</a></h4>
                                        <div className="sub-text">IT Networking</div>
                                    </div>
                                    <div className="text-content">
                                        <div className="text">Workflow management is the discipline of creating, documenting, monitoring and improving upon the series of steps, or workflow, that is required to complete a specific task. </div>
                                        <div className="link-box"><a href='/casestudies'>View Case Study <span className="arrow fa fa-arrow-right"></span></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                   
                    <div className="slide-item">
                 
                        <div className="case-block">
                            <div className="inner-box">
                                <figure className="image-box">
                                    <a href="/casestudies"><img src={require('../../images/resource/case-image-2.jpg')} alt="case-image-2" title=""/></a>
                                </figure>
                                <div className="content-box">
                                    <div className="title-box">
                                        <h4><a href='/casestudies'>Data Machine Learning</a></h4>
                                        <div className="sub-text">Artificial Intelligence</div>
                                    </div>
                                    <div className="text-content">
                                        <div className="text">Data cloud is a crucial component in the field of Machine Learning.It refers to the set of observations or measurements that can be used to train a machine-learning model.</div>
                                        <div className="link-box"><a href='/casestudies'>View Case Study <span className="arrow fa fa-arrow-right"></span></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                   
                    <div className="slide-item">
                 
                        <div className="case-block">
                            <div className="inner-box">
                                <figure className="image-box">
                                    <a href="/casestudies"><img src={require('../../images/resource/case-image-1.webp')} alt="case-image-1" title=""/></a>
                                </figure>
                                <div className="content-box">
                                    <div className="title-box">
                                        <h4><a href='/casestudies'>Workflow Management</a></h4>
                                        <div className="sub-text">IT Networking</div>
                                    </div>
                                    <div className="text-content">
                                        <div className="text">Workflow management is the discipline of creating, documenting, monitoring and improving upon the series of steps, or workflow, that is required to complete a specific task. </div>
                                        <div className="link-box"><a href='/casestudies'>View Case Study <span className="arrow fa fa-arrow-right"></span></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                   
                    <div className="slide-item">
                 
                        <div className="case-block">
                            <div className="inner-box">
                                <figure className="image-box">
                                    <a href="/casestudies"><img src={require('../../images/resource/case-image-2.jpg')} alt="case-image-2" title=""/></a>
                                </figure>
                                <div className="content-box">
                                    <div className="title-box">
                                        <h4><a href='/casestudies'>Data Machine Learning</a></h4>
                                        <div className="sub-text">Artificial Intelligence</div>
                                    </div>
                                    <div className="text-content">
                                        <div className="text">Data cloud is a crucial component in the field of Machine Learning.It refers to the set of observations or measurements that can be used to train a machine-learning model.</div>
                                        <div className="link-box"><a href='/casestudies'>View Case Study <span className="arrow fa fa-arrow-right"></span></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </OwlCarousel>
            </div>

            <div className="bottom-text">
                <div className="text">We’re ready to discover and unlock your potential. <strong>Call us Today!</strong> &nbsp;<a href="tel:+91 8903500146"><strong>+91 8903500146</strong></a></div>
            </div>

        </div>
    </section>

    <section className="testimonials-two">
        <div className="image-layer"  style={{ background: `url(${Background9})`}} ></div>

        <div className="auto-container">
            <div className="sec-title centered">
                <div className="upper-text">Read The Testimonials</div>
                <h2>What Clients Says <strong>About Us</strong></h2>
                <div className="lower-text">Sit amet consectetur adipisicing elitm sed eiusmod temp sed incididunt labore dolore magna aliquatenim veniam quis ipsum nostrud exer citation ullamco laboris.</div>
            </div>

            <div className="carousel-box">
            <OwlCarousel className="testimonial-carousel theme-carousel owl-theme owl-carousel" loop="true" margin={30} autoheight="true" lazyload="true" nav= "true" dots= "true" autoplay= "true" autoplayTimeout= {5000} smartSpeed= {500}  items= {1}>
                   
                    <div className="testimonial-block">
                        <div className="inner">
                            <div className="content-box">
                                <div className="content">
                                    <div className="text">Fugiat nulla pariatur excepteur sint occaecat dolor cupidatat non proident, sunt in culp qui officia quisy deserunt mollit anim id est laborum. Sed untminim perspiciatis unde omnis iste.</div>
                                </div>
                            </div>
                            
                            <div className="info">
                                <div className="image"><img src={require('../../images/resource/author-thumb-1.jpg')} alt="author-thumb-1"/></div>
                                <div className="name">Nelson Hilly</div>
                                <div className="designation">Technical</div>
                                <div className="rating"><span className="fa fa-star"></span> <span className="fa fa-star"></span> <span className="fa fa-star"></span> <span className="fa fa-star"></span> <span className="fa fa-star"></span></div>
                            </div>
                        </div>
                    </div>
                   
                    <div className="testimonial-block">
                        <div className="inner">
                            <div className="content-box">
                                <div className="content">
                                    <div className="text">Fugiat nulla pariatur excepteur sint occaecat dolor cupidatat non proident, sunt in culp qui officia quisy deserunt mollit anim id est laborum. Sed untminim perspiciatis unde omnis iste.</div>
                                </div>
                            </div>
                            
                            <div className="info">
                                <div className="image"><img src={require('../../images/resource/author-thumb-2.jpg')} alt="author-thumb-2"/></div>
                                <div className="name">Thomas Shely</div>
                                <div className="designation">Technical</div>
                                <div className="rating"><span className="fa fa-star"></span> <span className="fa fa-star"></span> <span className="fa fa-star"></span> <span className="fa fa-star"></span> <span className="fa fa-star"></span></div>
                            </div>
                        </div>
                    </div>
                   
                    <div className="testimonial-block">
                        <div className="inner">
                            <div className="content-box">
                                <div className="content">
                                    <div className="text">Fugiat nulla pariatur excepteur sint occaecat dolor cupidatat non proident, sunt in culp qui officia quisy deserunt mollit anim id est laborum. Sed untminim perspiciatis unde omnis iste.</div>
                                </div>
                            </div>
                            
                            <div className="info">
                                <div className="image"><img src={require('../../images/resource/author-thumb-3.jpg')} alt="author-thumb-3"/></div>
                                <div className="name">Peter Willy</div>
                                <div className="designation">Technical</div>
                                <div className="rating"><span className="fa fa-star"></span> <span className="fa fa-star"></span> <span className="fa fa-star"></span> <span className="fa fa-star"></span> <span className="fa fa-star"></span></div>
                            </div>
                        </div>
                    </div>

                </OwlCarousel>
            </div>
        </div>

    </section>

    <section className="contact-section">
        <div className="map-pattern-layer"></div>

        <div className="auto-container">
            <div className="row clearfix">
               
                <div className="text-column col-lg-6 col-md-12 col-sm-12">
                    <div className="inner">
                        <div className="sec-title">
                            <div className="upper-text">Get In Touch With Us Today!</div>
                            <h2><strong>Need Our Services? <br/>Book Your Appointment</strong></h2>
                            <div className="lower-text">We Help Customers Digital Transformation By IT Solutions</div>
                        </div>

                        <ul className="info">
                            <li><div className="phone-title">call us for support</div></li>
                            <li className="phone"><a href="tel:+91 8903500146"><span className="icon sl-icon-call-in"></span> <strong>+91 89035001466</strong></a></li>
                            <li className="email"><a href="mailto:support@loudping.com">info@kloudping.com</a></li>
                        </ul>
                    </div>
                </div>
               
                <div className="form-column col-lg-6 col-md-12 col-sm-12">
                    <div className="inner">
                        
                        <div className="form-box">
                            <div className="default-form appointment-form">
                                <form method="post" action="">
                                    <div className="row clearfix">                                    
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group txtOnly">
                                            <input type="text" name="field-name" placeholder="Your Name" required="" defaultValue=""/>
                                        </div>
                                        
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <input type="email" name="field-name" placeholder="Email" required="" defaultValue=""/>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group numberOnly">
                                            <input type="text" name="field-name" placeholder="Phone" required="" defaultValue=""/>
                                        </div>

                                        <div className="form-group col-md-6 col-sm-12">
                                            <select className="custom-select-box" name="field-name">
                                                <option>Inquiry About</option>
                                                <option>IT Cloud Integration</option>
                                                <option>SaaS Product Development</option>
                                                <option>IT CloudOperation</option>
                                            </select>
                                        </div>

                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <textarea name="field-name" placeholder="Message" required=""></textarea>
                                        </div>
                
                                        <div className="form-group col-md-12 col-sm-12">
                                            <a href='/#contact' className="theme-btn btn-style-one"><span className="btn-title">Make a Request</span></a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>

            </>
        )
    }
}
export default service
