import React, { Component } from 'react'
import Back8 from '../../images/background/banner-bg-1.jpg'
import Button from 'react-bootstrap/Button';

function scrollToContact() {
    const contactSection = document.getElementById('contact');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  }
class Microsoft_Stack extends Component {
  componentDidMount() {
     }
  render() {
      return (
          <>
              <section className="inner-banner alternate">
        <div className="image-layer" style={{ background: `url(${Back8})`}}></div>
        <div className="auto-container">
            <div className="inner">
                <div className="title-box">
                    <h1>Microsoft Certified Full-Stack Developers</h1>
                    <div className="d-text">Building a relationship between IT Services</div>
                </div>
            </div>
        </div>
              </section>
              <div className="sidebar-page-container services-page">
        <div className="auto-container">
                      <div className="row clearfix">
                      <div className="content-side col-lg-8 col-md-12 col-sm-12">
                    <div className="services-content">
                        <div className="service-details">
                            <div className="image-box">
                                          <img src={require('../../images/resource/service-single-image.jpg')} alt="service-single-image"/>
                            </div>
                            
                            <div className="content">
                                <h3>Business Security</h3>
                                <div className="text">
                                <p>Securing your business through software is imperative in today's digital landscape. To fortify your defenses, assess your specific security needs, invest in firewalls, antivirus, and email security software.</p>
                                    <p>Encryption is essential for safeguarding sensitive data, and access controls should be implemented to restrict unauthorized entry. Regular software updates, data backups, and an incident response plan are non-negotiable. </p>
                                    <p>Employee training and compliance with regulations are vital aspects of your security strategy. Collaborating with IT consultants can provide valuable insights and expertise. Keep in mind that security is an ongoing process; you must regularly review and update your security measures to adapt to evolving cyber threats.</p>
                                </div>
                                
                                <div className="two-col">
                                    <div className="row clearfix">
                                        <div className="column col-lg-6 col-md-6 col-sm-12">
                                                      <div className="image"><img src={require('../../images/resource/featured-image-4.jpg')} alt="featured-image-4"/></div>
                                        </div>
                                        <div className="column col-lg-6 col-md-6 col-sm-12">
                                                      <div className="image"><img src={require('../../images/resource/featured-image-5.jpg')} alt="featured-image-5"/></div>
                                        </div>
                                    </div>
                                </div>
                                <h3>Microsoft_Stack Enterprise Solutions</h3>
                                <div className="text">
                                    <p>Kloudping provides comprehensive Java enterprise solutions, enabling businesses to build scalable and secure applications that meet the complex demands of enterprise-level environments. Our Java experts leverage cutting-edge technologies and frameworks to deliver robust and reliable enterprise solutions.</p>

                                    <ul className="list-style-one">
                                        <li>Scalable and secure applications for enterprise</li>
                                        <li>Cutting-edge Java technologies and frameworks</li>
                                        <li>Robust and reliable solutions</li>
                                        <li>Enterprise-level Java solutions</li>   
                                        <Button variant="primary" size="lg"   onClick={() => scrollToContact()}>   Let's Connect for your Project </Button>{' '}                  
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                    <aside className="sidebar services-sidebar">
                        
                        <div className="sidebar-widget services-widget">
                            <div className="widget-inner">
                                <ul>
                                    <li><a href='/datainfrastructure'>Data Infrastructure</a></li>
                                    <li><a href='/cloudintegration' >IT Cloud Integration</a></li>
                                    <li><a href='/itstartup'>IT Startup Projects</a></li>
                                    <li><a href='/productengineering'>Product Engineering</a></li>
                                    <li className="active"><a href='/businesssecurity' >Business Security</a></li>
                                </ul>
                            </div>
                        </div>
                        
                        
                        
                        <div className="sidebar-widget info-widget">
                            <div className="widget-inner">
                                          <div className="image"><a href="/contact"><img src={require('../../images/resource/featured-image-4.jpg')} alt="featured-image-4"/></a></div>
                                <div className="lower">
                                    <div className="subtitle">Got any Questions? <br/>Call us Today!</div>
                                    <div className="icon-box"><span className="flaticon-telephone"></span></div>
                                    <div className="phone"><a href="tel:+91 89035 00146">+91 89035 00146</a></div>
                                    <div className="email"><a href="mailto:info@kloudping.com">info@kloudping.com</a></div>
                                </div>
                            </div>
                        </div>
                    </aside>
                </div>
                
            </div>
        </div>
    </div>

    <div className="theme-separator"></div>
              <section className="contact-section-two" id="contact"> 
                <div className="auto-container">
                    
                    <div className="upper-row">
                        <div className="row clearfix">

                            <div className="text-column col-lg-6 col-md-12 col-sm-12">
                                <div className="inner">
                                    <div className="sec-title">
                                        <div className="upper-text">Kloudping - Send us a Message</div>
                                        <h2>Do You Have Any Questions? We’ll Be Happy To Assist!</h2>
                                        <div className="lower-text">We will respond to your inquiry as soon as possible. Right now, we receive many inquiries and it may take a little longer than normal before you get a reply. If you need urgent help, we recommend that you call us during our opening hours..</div>
                                    </div>

                                    <div className="social-links">
                                        <ul className="clearfix">
                                            <li><a href="https://www.linkedin.com/company/kloudping" target='_blank'><span className="fab fa-linkedin"></span></a></li>
                                            <li><a href="#"><span className="fab fa-twitter"></span></a></li>
                                            <li><a href="https://www.youtube.com/@Kloudping"  target='_blank'><span className="fab fa-youtube"></span></a></li>
                                            <li><a href="https://wa.me/+918903500146" target='_blank'><span className="fab fa-whatsapp"></span></a></li>
                                            <li><a href="#"><span className="fab fa-facebook-square"></span></a>
                                            </li> 
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="form-column col-lg-6 col-md-12 col-sm-12">
                               <div className="inner">
                                 <div className='form-box'>
                                      <div className="default-form contact-form">
                                            <div className="row clearfix">
                                                   <h2>Contact Us</h2> 
                                                   <p>Fill out the form and we'll be in touch soon!</p>
                                                <div className="col-lg-6 col-md-6 col-sm-12 form-group txtOnly">
                                                    <input id="name" type="text" name="username" placeholder="Your Name" required="" defaultValue="" />
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                    <input id ="email"  type="email" name="email" placeholder="Email"  />
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 form-group numberOnly">
                                                    <input id="phone"   type="text" name="phone"  placeholder="Phone"  />
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                    <input  id="subject"  type="text" name="subject" placeholder="Subject"  />
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                                    <textarea id="message" name="message" placeholder="Message" ></textarea>
                                                </div>
                                                <div className="form-group col-md-12 col-sm-12">
                                                    <button  type="submit" className="theme-btn btn-style-one"><span className="btn-title" >Make a Request</span></button>
                                                </div>
                                           </div>    
                                      </div>
                                  </div>
                               </div>
                            </div>

                        </div>
                    </div>

                   
                </div>
            </section>
    
              <div className="theme-separator"></div>
              </>
        )
    }
}
export default Microsoft_Stack
