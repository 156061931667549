import React, { Component } from 'react'
import Back4 from '../../images/background/datainfra.jpg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {Link} from 'react-router-dom'

class Datainfrastructure extends Component {
  componentDidMount() {
     }
  render() {
      return (
        <>
           <section className="inner-banner alternate">
        <div className="image-layer" style={{ background: `url(${Back4})` ,backgroundRepeat: "no-repeat",   backgroundPosition: "0% 50%", backgroundSize: "100% 200%"}}></div>
        <div className="auto-container">
            <div className="inner">
                <div className="title-box">
                    <h1>Devops</h1>
                    <div className="d-text">Building a relationship between IT Services</div>
                </div>
            </div>
        </div>
          </section>
          <div className="sidebar-page-container services-page">
        <div className="auto-container">
            <div className="row clearfix">
                <div className="content-side col-lg-8 col-md-12 col-sm-12">
                    <div className="services-content">
                        <div className="service-details">
                            <div className="image-box">
                        <img src={require('../../images/resource/service-single-image.jpg')} alt="service-single-image"/>
                      </div>
                      <div className="content">
                                <h3>Devops</h3>
                                <div className="text">
                                    <p>At Kloudping IT Solutions, our DevOps services revolve around enhancing collaboration, automating processes, and optimizing software delivery. We prioritize Continuous Integration (CI) and Continuous Delivery/Deployment (CD), emphasizing Automation, Collaboration, and Communication. Our technical proficiency extends across various cloud platforms, ensuring that clients receive cutting-edge solutions tailored to their unique DevOps requirements.</p>
                        </div>
                        <div className="two-col">
                                    <div className="row clearfix">
                                        <div className="column col-lg-6 col-md-6 col-sm-12">
                              <div className="image"><img src={require('../../images/resource/featured-image-4.jpg')} alt="featured-image-4"/></div>
                                        </div>
                                        <div className="column col-lg-6 col-md-6 col-sm-12">
                              <div className="image"><img src={require('../../images/resource/featured-image-5.jpg')} alt="featured-image-5"/></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text">
                                <h3>Kloudping comprehensive DevOps offerings include:</h3>

                                    <ul className="list-style-one">
                                        <li> <b>Containerization & Orchestration:</b>
Streamline deployment and scalability using Docker and Kubernetes, ensuring consistency and efficiency in containerized applications.
.</li>
                                        <li><b>Infrastructure as Code (IaC):</b>
Implement infrastructure automation with Terraform and Ansible, enabling code-driven provisioning and configuration management.
 </li>
                                        <li><b>CI/CD Pipelines:</b>
Accelerate software delivery through Jenkins, GitLab CI, and GitHub Actions, ensuring seamless integration, testing, and deployment.
</li>
                                        <li><b>Cloud Platforms:</b>
Leverage AWS and Azure for robust cloud solutions, capitalizing on platform-specific services and features</li>
                                        <li><b>Monitoring & Logging:</b>
Ensure system reliability with Prometheus, Grafana, ELK, Splunk, and SonarQube, enabling real-time monitoring and proactive issue resolution.
</li>
                                        <li><b>Security Automation:</b>
Implement Veracode for security assessments, integrating automated security checks into the development pipeline.
</li>
<li><b>Microservices Architecture:</b>
Architect scalable and modular systems using microservices, promoting flexibility and ease of maintenance.

</li>                            </ul>
                                    
<h3>DevOps Tech Stack Expertise:</h3>
 
 <b>Our engineers at Kloudping IT Solutions are adept in a diverse tech stack, including:</b>
 <ul className="list-style-one">
 <li> Containerization: Docker, Kubernetes</li>
 <li> Infrastructure as Code: Terraform, Ansible</li> 
 <li> CI/CD: Jenkins, GitLab CI, GitHub Actions</li> 
 <li> Cloud Platforms: AWS, Azure</li> 
 <li> Monitoring & Logging: Prometheus, Grafana, ELK, Splunk, SonarQube</li> 
 <li> Security Automation: Veracode</li> 
 <li> Microservices: Architecture design and implementation</li> 
 </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                    <aside className="sidebar services-sidebar">
                        <div className="sidebar-widget services-widget">
                            <div className="widget-inner">
                                <ul>
                                    <li className="active"><a href='/Devops'>Devops</a></li>
                                    <li><a href='/cloudintegration'>IT Cloud Integration</a></li>
                                    <li><a href='/Migration'>IT Cloud Migration</a></li>
                                    <li><a href='/Operation'>Cloud Operation</a></li>
                                    <li><a href='/SAAS' >SaaS Product Development</a></li>
                                </ul>
                            </div>
                    </div>
                   
                    <div className="sidebar-widget info-widget">
                            <div className="widget-inner">
                        <div className="image"><Link to="contact"><img src={require('../../images/resource/featured-image-4.jpg')} alt="featured-image-4"/></Link></div>
                                <div className="lower">
                                    <div className="subtitle">Got any Questions? <br/> Call us Today!</div>
                                    <div className="icon-box"><span className="flaticon-telephone"></span></div>
                                    <div className="phone"><a href="tel:+91 8903500146">+91 8903500146</a></div>
                                    <div className="email"><a href="mailto:info@kloudping.com">info@kloudping.com</a></div>
                                </div>
                            </div>
                        </div>
                    </aside>
                </div>
                
            </div>
        </div>
          </div>
          <div className="theme-separator"></div>
                 </>
        )
    }
}
export default Datainfrastructure