import React, { Component } from 'react'
import "../footer/footer.css";
import {Link} from 'react-router-dom'
class footer extends Component {
    componentDidMount() {


    }
    render() {
        return (
            <>
                <footer className="main-footer">
                    <div className="top-pattern-layer-dark"></div>


                    <div className="widgets-section">
                        <div className="auto-container">
                            <div className="row clearfix">


                                <div className="column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                                    <div className="footer-widget about-widget">
                                        
                                        <div className="logo">
                                            <a href="/"><img src={require('../../../images/logo.png')} alt="footer-logo" className='footer logo' /></a>
                                        </div>
                                        <div className="text">
                                            We offer tailored cloud computing solutions for diverse platforms.<br></br> With expertise in <strong>AWS,Azure,GCP,E2E
                                            Cloud,and Heroku</strong> we excel<br></br> in migration and secure infrastructure design. Our focus on scalability,<br></br>
                                            adherence to best practices, and adept support from certified engineers<br></br> ensures optimal cloud performance.Serving 
                                            <strong> e-commerce, education,<br></br> healthcare, finance, media</strong> and more, we have a proven track record.<br></br> Trust us
                                            to deliver efficient cloud solutions aligned with your business goals.
                                        </div>
                                        <div className="info">
                                            <ul>
                                                <li>No:28,Near GH,Scudder Nagar,Tindivanam,Tamil Nadu 604001</li>
                                                <li><span className="icon sl-icon-call-in"></span> <a href="tel:+91 89035 00146"><strong>+91 89035 00146</strong></a></li>

                                                <li>
                                                    <span className="icon sl-icon-envelope-open"></span><a href="mailto:&#105;&#110;&#102;&#111;&#064;&#107;&#108;&#111;&#117;&#100;&#112;&#105;&#110;&#103;&#046;&#099;&#111;&#109;">
                                                        <strong> info@kloudping.com  </strong>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                                    <div className="footer-widget links-widget">
                                        <div className="widget-content">
                                            <div className="row clearfix">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <div className="row clearfix">
                                                        <div className="column col-lg-4 col-md-4 col-sm-12">
                                                            <div className="widget-title">
                                                                <h4>SERVICES </h4>
                                                            </div>
                                                            <ul className="links">
                                                                <li><Link to='businesssecurity'>DevOps</Link></li>
                                                                <li><Link to='businesssecurity'>Cloud Computing</Link></li>
                                                                <li><Link to='cloudintegration'>Cloud Infrastructure, On Permises to Cloud </Link></li>
                                                                <li><Link to='businesssecurity'>Cloud Secuirty, Workflow Automation Development</Link></li>
                                                                <li><Link to='itstartup'>CRMS, HRMS, Health Care Product, Dairy Product Developments</Link></li>
                                                                <li><Link to='itstartup'>Trading Software Development</Link></li>
                                                                <li><Link to='itstartup'>AI & ML OPS</Link></li>
                                                                <li><Link to='cloudintegration'>Web Application Development/ API Development </Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="column col-lg-4 col-md-4 col-sm-12">
                                                            <div className="widget-title">
                                                                <h4>What We Do ?</h4>
                                                            </div>
                                                            <ul className="links">
                                                                <li><Link to='about'>Understand Client's Needs</Link></li>
                                                                <li><Link to='casestudies'>Partner With Clients</Link></li>
                                                                <li><Link to='faq'>Trust Worthy Approach</Link></li>
                                                                <li><Link to='faq'>Highly Skilled Team</Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="column col-lg-4 col-md-4 col-sm-12">
                                                            <div className="widget-title">
                                                                <h4>Support</h4>
                                                            </div>
                                                            <ul className="links">
                                                                <li><Link to='blogfullPost'>View Help Topics</Link></li>
                                                                <li><Link to='News'>Latest Articles</Link></li>
                                                                <li><Link to='blogfullPost'>Technology Consulting</Link></li>
                                                                <li><Link to='contact'>Terms Of Service</Link></li>
                                                                <li><Link to='contact'>Contact Us</Link></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="footer-bottom">
                        <div className="auto-container">
                            <div className="inner">
                                <div className="copyright">&copy; 2023 <strong>Kloudping IT Solutions</strong>. All rights reserved. <Link to='privacypolicy'>Privacy Policy</Link></div>
                            </div>
                        </div>
                    </div>

                </footer>

            </>
        )
    }
}


export default footer