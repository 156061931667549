import React, { Component } from 'react'
import Back7 from '../../images/background/productengg.jpg'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
class Operation extends Component {
  componentDidMount() {
     }
  render() {
      return (
        <>
          <section className="inner-banner alternate">
        <div className="image-layer" style={{ background: `url(${Back7})`,backgroundRepeat: "no-repeat",   backgroundPosition: "0% 55%", backgroundSize: "110% 220%"}}></div>
        <div className="auto-container">
            <div className="inner">
                <div className="title-box">
                    <h1>Cloud operation</h1>
                    <div className="d-text">Building a relationship between IT Services</div>
                </div>
            </div>
        </div>
          </section>
          <div className="sidebar-page-container services-page">
          <div className="auto-container">
              <div className="row clearfix"> 
              <div className="content-side col-lg-8 col-md-12 col-sm-12">
                    <div className="services-content">
                        <div className="service-details">
                            <div className="image-box">
                                <img src={require('../../images/resource/service-single-image.jpg')} alt="service-single-image"/>
                    </div>
                    <div className="content">
                                <h3>Cloud operations</h3>
                                <div className="text">
                                    <p>Our approach revolves around automation and orchestration techniques, optimizing cloud deployments and enhancing overall operational efficiency.</p>
                                    {/* <p>We offer full-cycle development services, from ideation to maintenance, and have expertise in various technologies. We create prototypes and MVPs for rapid idea validation and maintain a strong focus on scalability, performance, UX/UI design, security, and quality assurance.</p>
                                    <p>We ensure seamless integration and compatibility with existing systems and offer ongoing support. By optimizing costs and project management, We can help businesses turn concepts into reliable, efficient, and competitive software products.</p> */}
                      </div>
                      <div className="two-col">
                                    <div className="row clearfix">
                                        <div className="column col-lg-6 col-md-6 col-sm-12">
                            <div className="image"><img src={require('../../images/resource/featured-image-4.jpg')} alt="featured-image-4"/></div>
                                        </div>
                                        <div className="column col-lg-6 col-md-6 col-sm-12">
                            <div className="image"><img src={require('../../images/resource/featured-image-5.jpg')} alt="featured-image-5"/></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text">
                                    <h3>Kloudping Comprehensive Cloud Operations Offerings:</h3>
                                    <p><b>Automation and Orchestration Techniques</b>Streamlining cloud operations through meticulous automation and orchestration techniques. Implementing deployment automation and monitoring automation for enhanced efficiency.</p>
                                    <ul className="list-style-one">
                                    <p><b>Cloud Operations Tech Stack Expertise</b></p>
                                        <li>In our Cloud Operations endeavors, our team excels in a tech stack designed to elevate your cloud experience. This includes expertise</li>
                                        <li>Automation: deployment automation, monitoring automation Pipelines: Streamlining cloud deployments </li>
                                        <li>By embracing these technologies, we empower businesses to achieve a proactive and efficient approach to cloud operations, minimizing manual intervention and ensuring a seamless operational landscape.</li>
                                        {/* <li>We act as partners in the journey of transforming ideas into reliable, efficient, and competitive software products, covering all aspects from inception to post-deployment maintenance.</li> */}
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
              </div>
              <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                    <aside className="sidebar services-sidebar">
                        <div className="sidebar-widget services-widget">
                            <div className="widget-inner">
                                <ul>
                                    <li><a href='/Devops'>Devops</a></li>
                                    <li><a href='/cloudintegration'>IT Cloud Integration</a></li>
                                    <li><a href='/Migration'>IT Cloud Migration</a></li>
                                    <li className="active"><a href='/Operation'>IT Cloud operation</a></li>
                                    <li><a href='/SAAS'>SaaS Product Development</a></li>
                                </ul>
                            </div>
                  </div>
                 
                  <div className="sidebar-widget info-widget">
                            <div className="widget-inner">
                      <div className="image"><a href="/contact"><img src={require('../../images/resource/featured-image-4.jpg')} alt="featured-image-4"/></a></div>
                                <div className="lower">
                                    <div className="subtitle">Got any Questions? <br/>Call us Today!</div>
                                    <div className="icon-box"><span className="flaticon-telephone"></span></div>
                                    <div className="phone"><a href="tel:+91 89035 00146">+91 89035 00146</a></div>
                                    <div className="email"><a href="mailto:info@kloudping.com.net">info@kloudping.com.net</a></div>
                              </div>
                        
                            </div>
                        </div>
                    </aside>
                  </div>
                </div>
            </div>
            </div>
          <div className="theme-separator"></div>
         </>
                )
            }
        }
export default Operation