import React from 'react';
import { createChatBotMessage } from 'react-chatbot-kit';
import StartBtn from './Bot/StartBtn';
import StartSlow from './Bot/StartSlow';
import data from './data';
import DipslayImage from './Bot/DisplayImage';
import BotAvatar from './Bot/botAvatar.jsx';


const config = {
  botName: ' kloudping  ',
  initialMessages: [createChatBotMessage(`Hi! Welcome to kloudping IT Solutions`,{
    widget: "startBtn"
})],
  customComponents: {
   
   botAvatar: (props) => <BotAvatar {...props} />,
  
 },
 customStyles: {
  botMessageBox: {
    backgroundColor: '#376B7E',
  },
  chatButton: {
    backgroundColor: '#5ccc9d',
  },
},
state: {
  checker: null,
  data,
  userData: {
      name: "",
      age: 0,
      category: "",
      product: {
          name: "",
          link: "",
          imageUrl: ""
      }
  }
},
widgets: [
  {
      widgetName: "startBtn",
      widgetFunc: (props) => <StartBtn {...props} />,
  },
  {
      widgetName: "startSlow",
      widgetFunc: (props) => <StartSlow {...props} />,
  },
  {
      widgetName: "finalImage",
      widgetFunc: (props) => <DipslayImage {...props} />,
  },
]
};

export default config;